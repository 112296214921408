.container__map {
  display: inline-block;
}

.page-layout__viewport {
  height: 100%;
  width: 100%;

  @include breakpoint(md) {
    height: calc(100vh - 40px);
    position: relative;
    display: flex;
    flex-flow: row-reverse;
    overflow: hidden;
  }
}

.page-layout__nav-item--active {
  font-weight: bold;
  text-decoration: underline;
}
