@import "../../../styles/mixins";

.EasternRegionIndicator {
  padding: 5px;
  cursor: pointer;
  margin-right: -20px;

  &:hover {
    cursor: pointer;
  }

  img {
    height: 14px;
    width: 14px;
  }
}

.sidebar__container {
  &.open {
    .EasternRegionIndicator {
      display: none;

      @include breakpoint(md) {
        display: block;
      }

      @include breakpoint(xl) {
        padding-right: 32px;
      }

      @include breakpoint(xxxl) {
        padding-right: 19px;
      }
    }
  }
}

.eastern__tooltip {
  &.rc-tooltip-placement-easternRight {
    .rc-tooltip-arrow {
      width: 10px;
      height: 20px;
      top: 35%;
      left: -9px;
      margin-left: 0;

      &:before,
      &:after {
        top: -50px;
        left: 20px;
        margin-top: 10px;
      }

      &:after {
        left: 22px;
      }
    }
  }

  &.rc-tooltip-placement-easternBottomRight {
    .rc-tooltip-arrow {
      width: 20px;
      height: 10px;
      top: -9px;
      right: 20%;
      margin-left: -10px;

      &:before,
      &:after {
        left: -50px;
        margin-left: 10px;
        top: 20px;
      }

      &:after {
        top: 22px;
      }
    }
  }
}
