@use "sass:math";

@import "../../../../styles/vars";
@import "../../../../styles/mixins";

.marker {
  position: absolute;
  width: $marker-diameter;
  height: $marker-diameter;
  border-radius: 50% 50% 50% 0;
  border: 3px solid $color-bright-gray;
  background-color: rgba($color-bright-turquoise, 0.5);
  cursor: pointer;

  @include translate(math.div(-$marker-diameter, 2), -$marker-diameter);
  rotate: -45deg;

  &:focus {
    outline: 0;
  }

  &::after {
    position: absolute;
    content: "";
    width: $marker-dot-diameter;
    height: $marker-dot-diameter;
    border-radius: 50%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: $color-bright-gray;
  }
}

.cluster {
  position: absolute;

  border-radius: 50%;

  background-color: rgba($color-bright-turquoise, 0.5);

  color: $color-white;
  font-size: 20px;
  font-weight: bold;
  text-align: center;

  cursor: pointer;

  &:focus {
    outline: 0;
  }

  &.hybrid {
    background-color: rgba($color-bright-turquoise, 0.8);
    color: $color-black;
  }
}

// Riyadh city cluster
#city-3 {
  transform: scale(1.1) translateX(-10px) translateY(10px);

  @include breakpoint(sm) {
    transform: none;
  }
}
