@import "../../styles/mixins";

.founder-logo {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 100%;

  @include breakpoint(md, true) {
    padding-top: 128px;
  }
}

.founder-logo__container {
  margin-left: auto;
}

.founder-logo__note {
  display: block;
  margin-bottom: 9px;
  font-size: 10px;
  color: $color-ghost;
}

.founder-logo__image {
  max-width: 160px;
}

.founder-logo__box.eastern-region {
  display: flex;
  align-items: center;

  .founder-logo__note {
    margin-bottom: 0;
    margin-left: 20px;
    font-size: 14px;

    @include breakpoint(md) {
      font-size: 12px;
    }
  }

  .founder-logo__image {
    max-width: 32px;
  }
}

@media screen and (min-width: 1024px) and (max-height: 700px) {
  .founder-logo__box {
    display: flex;
    max-height: 50px;
    align-items: center;
    margin-top: 10px;
  }

  .founder-logo__note {
    line-height: 2.5;
    margin-bottom: 0;
  }

  .founder-logo__image {
    margin-right: 10px;
    width: 216px;
  }
}
