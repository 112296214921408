@import "../../../styles/vars";
@import "../../../styles/mixins";
@import "../../../styles/typography";

.panel-item__value--percentage {
  .percentage-value {
    @include breakpoint(md) {
      font-size: 40px;
    }

    @include breakpoint(xl) {
      font-size: 48px;
    }
  }

  .percentage-value__format {
    @extend .heading-5;

    position: relative;
    right: 5px;
  }
}
