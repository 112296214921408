@import "../../../../styles/vars";
@import "../../../../styles/mixins";

.loader__content {
  $viewport-height: 100vh;
  $header-height: 40px;
  $sections-number: 2;
  $mobile-section-height: 210px;
  $desktop-section-height: 240px;

  @include breakpoint(md) {
    height: calc(
      ((#{$viewport-height} - #{$header-height}) / #{$sections-number}) - #{$mobile-section-height}
    );
  }

  @include breakpoint(xl) {
    height: calc(
      ((#{$viewport-height} - #{$header-height}) / #{$sections-number}) - #{$desktop-section-height}
    );
  }
}

.loader__container {
  position: absolute;
  top: 55px;
  left: 0;

  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: 100%;

  z-index: 1;
}

.loader__container--darker {
  background-color: rgba($color-black, 0.75);
}

.loader__container--lighter {
  background-color: rgba($color-ebony-clay, 0.75);
}

.loader {
  background: url("../../../../assets/loader.gif");
  width: 60px;
  height: 60px;
}

.map__container {
  .loader__content {
    position: absolute;
    height: calc(100% + 5px);
    width: 100%;
  }

  .loader__container {
    top: 0;
    background-color: rgba($color-mirage, 0.5);
  }
}
