@import "../../../../styles/mixins";

.BubbleChart {
  margin: auto auto 30px;

  &--fill {
    margin-left: 15px;
  }

  @include breakpoint(sm) {
    margin-bottom: 0;
  }

  &__group {
    display: flex;
    justify-content: center;
  }

  &__items {
    width: 100%;

    .Item {
      &--spread {
        margin-left: 20px;
        margin-bottom: 24px;
      }

      &:last-of-type {
        margin-bottom: 0;
      }

      &__points {
        width: 100%;
      }
    }
  }
}
