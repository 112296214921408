@import "../../../styles/mixins";
@import "../../../styles/vars";

.rc-tooltip.base-tooltip {
  z-index: 2000;
  width: 200px;
  opacity: 1;

  .rc-tooltip-content {
    border-radius: 5px;

    > .rc-tooltip-arrow {
      display: none;
    }
  }

  .rc-tooltip-arrow {
    z-index: 1;
    border-color: inherit;

    &:after {
      border-color: inherit;
      background: $color-white;
      color: inherit;
    }
  }

  .rc-tooltip-inner {
    padding: 0;
    text-align: initial;
  }

  .tooltip {
    border-style: solid;
    border-width: 1px;
    border-radius: 5px;
    min-height: 34px;
    padding: 10px 0;

    background: $color-white;
    color: $color-havelock-blue;
  }
}

.rc-tooltip-placement-top.base-tooltip .rc-tooltip-arrow,
.rc-tooltip-placement-topLeft.base-tooltip .rc-tooltip-arrow,
.rc-tooltip-placement-topRight.base-tooltip .rc-tooltip-arrow {
  position: absolute;
  left: calc(50% - 8px);
  bottom: -6px;

  overflow: hidden;
  height: 16px;
  width: 16px;
  margin: 0;
  border: 0;

  transform: rotate(90deg);

  &:after {
    position: absolute;
    left: -60%;

    display: block;
    height: 100%;
    width: 100%;
    border-width: 1px;
    border-style: solid;

    content: "";
    transform: rotate(45deg);
  }
}

.rc-tooltip-placement-topRight.base-tooltip.chart.chart--small-width
  .rc-tooltip-arrow {
  right: 3%;
}
