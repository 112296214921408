.map__container {
  content: "viewport-units-buggyfill; height: calc(100vh - 95px);";

  @include breakpoint(md) {
    content: "viewport-units-buggyfill; height: calc(100vh - 40px);";
  }
}

.HeaderNav--active {
  content: "viewport-units-buggyfill; height: calc(100vh - 40px);";
}
