@import "../vars";
@import "../mixins";

.sectors-list {
  height: calc(100% - 110px);

  @include breakpoint(sm) {
    height: calc(100% - 140px);
  }

  @include breakpoint(md) {
    height: calc(100% - 160px);
  }

  @include breakpoint(xxl) {
    height: calc(100% - 90px);
  }

  @include breakpoint(xxxl) {
    height: calc(100% - 120px);
  }
}

.sectors-details {
  $mobile-back-to-map-btn-height: 60px;

  min-height: 400px;
  height: calc(100% - #{$mobile-back-to-map-btn-height});

  @media only screen and (max-width: 768px) and (orientation: landscape) {
    min-height: 100px;
  }

  .loader__container,
  .sectors-details__no-results {
    height: calc(100% - map-deep-get($container, mobile, spacing));

    @include breakpoint(sm) {
      height: calc(100% - map-deep-get($container, tablet, spacing));
    }

    @include breakpoint(xxl) {
      height: calc(100% - map-deep-get($container, small-desktop, spacing));
    }

    @include breakpoint(xxxl) {
      height: calc(100% - map-deep-get($container, desktop, spacing));
    }
  }
}

.sectors-details {
  position: relative;
  top: -11px;
  margin-bottom: 15px;

  @include breakpoint(sm) {
    margin-bottom: 20px;
  }

  @include breakpoint(md) {
    height: 100%;
    margin-bottom: 0;
  }

  @include breakpoint(xxxl) {
    top: -15px;
  }
}

.sectors-details__container {
  height: 100%;
}

.subsectors-enter {
  opacity: 0.01;
}

.subsectors-enter-active {
  opacity: 1;
  transition: opacity 600ms;
  -webkit-transition: opacity 600ms;
  -moz-transition: opacity 600ms;
}

.sectors__icon {
  border: solid 1px #fff;
  border-radius: 8px;
  cursor: pointer;
  display: inline-block;
  font-size: 13px;
  height: 15px;
  margin-right: 15px;
  position: relative;
  -webkit-transition: all 0.4s linear, top 0.4s, width 0.4s;
  -moz-transition: all 0.4s linear, top 0.4s, width 0.4s;
  transition: all 0.4s linear, top 0.4s;
  width: 15px;

  &::before {
    border-radius: 8px;
    color: #fff;
    margin: 0 !important;
    transition: all 0.4s linear;
    vertical-align: top;
    width: 13px;
  }

  @include breakpoint(md) {
    &:hover {
      border-color: $color-bright-turquoise;

      &::before {
        color: $color-bright-turquoise;
      }
    }
  }

  &.sectors__icon--active {
    background-color: $color-bright-turquoise;
    border-color: $color-bright-turquoise;
    position: relative;
    transform: rotateX(180deg);

    &::before {
      color: $color-ebony-clay;
    }
  }

  &.sectors__icon--disabled {
    cursor: default;
    color: $color-emperor;
  }
}

.sectors-details__list {
  position: relative;
  height: 100%;

  padding-top: 52px;

  @include breakpoint(sm) {
    padding-top: 40px;
  }
  @include breakpoint(md) {
    padding-top: 48px;
  }
  @include breakpoint(xxl) {
    padding-top: 40px;
  }
  @include breakpoint(xxxl) {
    padding-top: 56px;
  }

  .heading-6 {
    padding-bottom: 10px;
  }
}

.sectors-details__scrolled-list {
  -ms-overflow-style: none;
}

.sectors-details__grouped-items {
  margin-bottom: 20px;
  & > .sectors-details__list-item:first-child {
    margin-bottom: 12px;

    @include breakpoint(sm) {
      margin-bottom: 16px;
    }
  }

  .sectors-details__list-item {
    margin-bottom: 8px;
  }
}

.sectors-details__list-item {
  display: flex;
  align-items: center;
  margin-right: 7px;

  @include ltr() {
    padding-right: 0;
  }

  @include rtl() {
    padding-left: 0;
  }

  @include breakpoint(sm) {
    margin-right: 2px;

    @include ltr() {
      padding-right: 15px;
    }

    @include rtl() {
      padding-left: 15px;
    }
  }

  &.disabled {
    color: $color-bright-gray;
  }

  .sectors-details__item-name {
    cursor: pointer;
    transition: 0.1s;
    max-width: 100%;

    @include ltr() {
      padding-left: 18px;
    }

    @include rtl() {
      padding-right: 18px;
    }

    @include breakpoint(sm) {
      @include ltr() {
        padding-left: 26px;
      }

      @include rtl() {
        padding-right: 26px;
      }
    }
  }
}

.sectors-details__item-name {
  flex-shrink: 0;
}

.paragraph-1--dark-gray {
  margin-bottom: 8px;

  .sectors-details__item-name {
    @include ltr() {
      padding-left: 38px;
    }

    @include rtl() {
      padding-right: 38px;
    }

    @include breakpoint(sm) {
      @include ltr() {
        padding-left: 46px;
      }

      @include rtl() {
        padding-right: 46px;
      }
    }
  }
}

.sectors-details__list-item--with-dot {
  position: relative;
  .sectors-details__item-name {
    position: relative;

    .dot {
      width: 10px;
      height: 10px;
      display: inline-block;
      border-radius: 5px;
      background: aliceblue;
      position: absolute;
      top: calc(50%);
      transform: translateY(-50%);

      @include ltr() {
        left: 0;
      }

      @include rtl() {
        right: 0;
      }
    }
  }
  .icon-cancel {
    &:before {
      position: absolute;
      top: 12px;
      content: $icon-cancel;
      font-family: "geo-icons";
      color: $color-river-bed;
      cursor: pointer;

      @include ltr() {
        left: -30px;
      }

      @include rtl() {
        right: -30px;
      }
    }
  }
}

.sectors-details__list-item--with-dot.paragraph-1--dark-gray {
  .dot {
    width: 30px;
  }
}

.sectors-details__scroll-container {
  margin-right: -23px;

  .sectors-details__separator {
    border: none;
    border-top: 1px solid $color-oxford-blue;
    margin: 20px 24px 20px 0;

    @include breakpoint(sm) {
      margin-left: 42px;
    }
  }

  .sectors-details__separator:last-child {
    display: none;
  }
}

.sectors-details__no-results {
  @include ltr() {
    text-align: left;
  }

  @include rtl() {
    text-align: right;
  }
}

/**
 * Sectors search
 */
.sectors-details__search {
  .heading-4 {
    font-size: 20px;
    padding-bottom: 28px;

    @include breakpoint(sm) {
      padding-bottom: 40px;
    }
    @include breakpoint(md) {
      padding-bottom: 48px;
    }
    @include breakpoint(xxl) {
      padding-bottom: 40px;
    }
    @include breakpoint(xxxl) {
      font-size: 32px;
      padding-bottom: 56px;
    }
  }

  .autocomplete__input-container {
    max-width: 100%;
    border: solid 2px $color-bright-turquoise;
    border-radius: 27px;
    height: 54px;

    .autocomplete__input {
      background: none;
      border: none;
      color: white;
      height: 50px;
      line-height: 50px;
      width: calc(100% - 50px);
      box-shadow: none;
      padding: 2px 0 8px 12px;
      &::placeholder {
        line-height: 50px;
        color: $color-ghost;
      }
    }
  }

  .autocomplete__children-elements {
    color: $color-ghost;
    height: 50px;
    padding: 2px 0 8px 0;
    top: 2px;

    @include rtl() {
      left: 0;
    }
  }

  .autocomplete {
    top: 0;

    @include ltr() {
      left: 0;
    }

    @include rtl() {
      right: 0;
    }
  }

  .autocomplete.open {
    .autocomplete__input {
      width: calc(100% - 50px);
      font-size: 14px;
    }
  }

  .autocomplete.focused.typed {
    .autocomplete__input {
      border: none;
      border-radius: 27px;
      color: $color-ghost;
    }
  }
}

.sectors-details__search-container {
  @include breakpoint(sm) {
    position: relative;
  }

  .autocomplete__input {
    @include breakpoint(sm) {
      @include ltr() {
        padding-right: 130px;
      }

      @include rtl() {
        padding-left: 130px;
      }
    }
  }

  .geo-icon-search {
    color: $color-btn-primary-bg;
    background: none;
    width: 50px;
    height: 50px;
    border-radius: 5px;
    text-align: center;
    line-height: 50px;
  }

  .autocomplete .geo-icon-cancel {
    width: 50px;

    @include breakpoint(sm) {
      @include ltr() {
        right: 135px;
      }

      @include rtl() {
        left: 105px;
      }
    }
  }

  .sectors-details__remaining {
    font-family: $font-family-regular;
    opacity: 0.5;
    font-size: 14px;

    @include ltr() {
      text-align: left;
    }

    @include rtl() {
      text-align: right;
    }

    @include breakpoint(sm) {
      @include rtl() {
        padding-left: 20px;
      }

      @include ltr() {
        padding-right: 20px;
      }
    }
  }
}

.visible--on-desktop {
  display: none;

  @include breakpoint(sm) {
    display: block;
  }
}

.visible--on-mobile {
  display: block;

  @include breakpoint(sm) {
    display: none;
  }
}
