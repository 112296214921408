.no-data-wrapper {
  position: absolute;
  top: 55px;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.no-data-wrapper__overlay,
.no-data-wrapper__background {
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 1;
}

.no-data-wrapper__message {
  padding-top: 19rem;
}

.no-data-wrapper__overlay {
  margin: 0;
}

.no-data-wrapper__chart {
  opacity: 0.2;

  .simple-bar__line,
  .pyramid-label-sum {
    visibility: hidden;
  }
}

.no-data-wrapper__chart {
  .axis-x,
  .axis-y__line {
    visibility: hidden;
  }
}

.no-data-wrapper--demographic {
  top: 0;
  z-index: 3;

  .no-data-wrapper__message {
    padding: 0;
    height: 100%;
    display: flex;
  }
}
