@import "../../styles/mixins";
@import "../../styles/vars";

.translated-title {
  display: flex;
}

.translated-title__container {
  display: flex;
  align-items: center;

  @include rtl() {
    flex-direction: row-reverse;
  }
}

.translated-title__icon {
  margin: 0 12px 0 0;
  height: 20px;

  @include breakpoint(sm) {
    height: 24px;
  }

  @include rtl() {
    margin: 0 0 0 12px;
  }
}
