.sidebar__badge {
  position: relative;
  width: 100%;

  @include breakpoint(md) {
    width: initial;

    @include ltr() {
      padding-left: 2 * $sidebar-badge-padding;
    }

    @include rtl() {
      padding-right: 2 * $sidebar-badge-padding;
    }
  }
}

.sidebar__badge__icon {
  font-size: 18px;
  color: $color-bright-turquoise;

  @include ltr() {
    margin-right: 10px;
  }

  @include rtl() {
    margin-left: 10px;
  }

  &.geo-icon-saudi {
    width: 40px;
  }

  &.geo-icon-business {
    font-size: 22px;
  }
}
