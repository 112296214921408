@import "../../styles/mixins";

.sticky_sentinel {
  position: absolute;
  left: 0;
  right: 0;
  visibility: hidden;
}

.sticky_sentinel--top {
  height: 40px;
  top: -24px;
}

.sticky_sentinel--bottom {
  bottom: 0;
  height: 95px;

  @include breakpoint(md) {
    height: 60px;
  }
}
