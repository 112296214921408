@import "../../../../styles/mixins";
@import "../../../../styles/vars";

%icon-dimensions {
  width: 24px;
  height: 24px;
}

.IncomeTableItem {
  display: flex;
  justify-content: space-between;
  border-bottom: solid 2px $color-mirage;
}

.IncomeTableItem {
  &:first-child {
    [class^="geo-icon-"] {
      font-size: 24px;
    }
  }
}

.IncomeTableItem__label {
  min-width: 140px;

  line-height: 2.57;

  [class^="geo-icon-"] {
    @extend %icon-dimensions;

    font-size: 12px;

    &:before {
      margin: 0;
    }
  }
}

.IncomeTableItem__icon {
  @extend %icon-dimensions;

  margin-left: 8px;
  display: inline-block;
}

.IncomeTableItem__icon--center-align {
  display: flex;
  align-items: center;
  justify-content: center;
}

.IncomeTableItem__income {
  display: block;
  margin-top: 10px;
}

.IncomeTableItem:first-child {
  .IncomeTableItem__income {
    display: flex;
    align-items: flex-end;
    margin-bottom: 5px;
  }
}
