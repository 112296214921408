@import "../../styles/vars";
@import "../../styles/mixins";

.DownloadModal__header {
  color: $color-white;
  font-size: 20px;
  margin: 18px 0 0;

  @include breakpoint(fullHd) {
    font-size: 24px;
  }
}

.DownloadModal__message {
  border-bottom: 1px solid $color-trolley-grey;
  color: $color-trolley-grey;
  font-size: 16px;
  line-height: 1.5;
  margin: 20px 0 0;
  padding-bottom: 20px;
  width: 100%;

  @include breakpoint(fullHd) {
    font-size: 20px;
    margin: 24px 0 0;
  }
}

.DownloadModal__comment__header {
  color: $color-trolley-grey;
  font-size: 14px;
  margin: 20px 0 0;

  @include breakpoint(fullHd) {
    font-size: 18px;
  }
}

.DownloadModal__comment__list {
  list-style-position: inside;
  margin: 8px 0 0;
  padding-inline-start: 0;
}

.DownloadModal__comment__item {
  color: $color-trolley-grey;
  font-size: 14px;
  line-height: 1.5;

  @include breakpoint(fullHd) {
    font-size: 18px;
  }
}

.DownloadModal__comment__item::marker {
  font-size: 10px;
}

.DownloadModal__footer {
  color: $color-trolley-grey;
  font-size: 12px;
  margin: 20px 0 0;

  @include breakpoint(fullHd) {
    font-size: 16px;
  }
}

.DownloadModal__buttons {
  display: block;
  margin: 20px 0;

  @include breakpoint(md) {
    display: flex;
    justify-content: center;
    margin: 40px 0 0;
  }

  button {
    margin: 20px 0;

    @include breakpoint(md) {
      margin: 0 20px;
    }
  }
}
