@import "../../../../styles/vars";
@import "../../../../styles/mixins";

.SidebarItem {
  cursor: pointer;
  text-decoration: none;

  display: flex;
  align-items: center;
  flex-direction: row-reverse;

  .sidebar__item-wrapper--disabled & {
    cursor: default;
  }
}

.SidebarItem__icon-container {
  transition: transform 0.3s ease;
  opacity: 0.5;

  img {
    display: block;
  }

  .sidebar__item-wrapper--disabled & {
    opacity: 0.2;
  }

  .sidebar__item-wrapper--active & {
    opacity: 1;
    transform: rotate(-180deg);
  }
}

.SidebarItem__content {
  box-sizing: border-box;
  flex-grow: 1;
  padding-left: 3 * $sidebar-space;
  min-width: 0;
}
