@import "../../../../../styles/vars";
@import "../../../../../styles/mixins";

.fixed-axis-portal {
  bottom: 0;
  z-index: 3;

  background: $color-ebony-clay;

  @include breakpoint(md, true) {
    padding-bottom: 25px;
  }

  .x-axis__container {
    flex-basis: calc(50% - 36px);
    max-width: calc(50% - 36px);
  }

  .fixed-axis-portal__divider {
    flex-basis: 72px;
    max-width: 72px;
  }
}

.chart--simple-chart {
  .sticky_sentinel--bottom {
    bottom: 2px;
  }
}

.chart--pyramid:not(:first-child) {
  .fixed-axis-portal {
    margin-top: 35px;

    @include breakpoint(sm) {
      margin-top: 45px;
    }

    @include breakpoint(md) {
      margin-top: 70px;
    }

    &.reset-margin {
      margin-top: 0;
    }
  }

  .sticky_sentinel--top {
    top: -50vh;

    @include breakpoint(md) {
      top: -60vh;
    }
  }

  .sticky_sentinel--bottom {
    bottom: 5vh;
  }
}

.fixed-axis-portal__divider {
  border-top: 1px solid $color-ghost;
}

.fixed-axis-portal.axis-x--shadow {
  &:before {
    top: -50px;
  }
}
