@import "../../../../styles/vars";
@import "../../../../styles/mixins";

.map__wrapper {
  display: flex;
  flex-direction: column;

  @include breakpoint(md) {
    position: relative;
    flex-direction: row-reverse;
  }
}

.map__container {
  position: relative;
  height: calc(100vh - 150px);

  @include breakpoint(sm) {
    max-height: 568px;
  }

  @include breakpoint(md) {
    z-index: 1;
    height: calc(100vh - 40px);
    min-height: 550px;
    flex-grow: 1;
    flex-basis: 0;

    max-height: none;
    border-width: 0 5px 5px 0;
    border-style: solid;
    border-color: $color-black;
  }
}

.map {
  height: 100%;
  // map needs the index to fix the issue with overlapping the sidebar https://910ths.atlassian.net/browse/AG-982
  z-index: 1;

  // for the onboarding-search step the index needs to be unset in order to highlight the onboarding target
  &--onboarding {
    z-index: unset;
  }
}

@media only screen and (max-width: 768px) and (orientation: landscape) {
  .map__container {
    height: calc(100vh - 150px);
  }
}

.gm-style {
  z-index: initial !important;

  iframe + div {
    z-index: 1 !important;
  }
}
