@import "../../styles/vars";
@import "../../styles/mixins";

.Popup__overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba($color-black, 0.7);
  z-index: 100;
}

.Popup {
  display: flex;
  position: absolute;
  justify-content: center;
  flex-flow: column;
  text-align: center;
  background-color: $color-mirage;
  border-radius: 17px;
  height: fit-content;
  width: 324px;
  padding: 28px 24px;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.31);

  @include breakpoint(sm) {
    width: 504px;
    padding: 28px 80px;
  }

  @include breakpoint(md) {
    width: 608px;
    padding: 40px 88px;
  }

  @include breakpoint(xxl) {
    width: 504px;
    padding: 28px 80px;
  }

  @include breakpoint(fullHd) {
    width: 608px;
    padding: 40px 88px;
  }
}

.Popup__header {
  font-size: 20px;
  color: $color-white;
  margin-bottom: 20px;

  @include breakpoint(sm) {
    font-size: 28px;
  }

  @include breakpoint(md) {
    font-size: 34px;
  }

  @include breakpoint(xxl) {
    font-size: 28px;
  }

  @include breakpoint(fullHd) {
    font-size: 34px;
  }
}

.Popup__text {
  font-size: 14px;
  margin-bottom: 28px;
  line-height: 1.44;

  @include breakpoint(sm) {
    font-size: 16px;
  }

  @include breakpoint(md) {
    font-size: 18px;
  }

  @include breakpoint(xxl) {
    font-size: 16px;
  }

  @include breakpoint(fullHd) {
    font-size: 18px;
  }
}

.Popup__buttons {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.Popup__body--open {
  overflow: hidden;
}
