@import "../../../styles/vars";

.render-pdf-points-tooltip {
  border: 1px solid $color-havelock-blue;
  border-radius: 8px;
  width: 310px;
  min-height: 160px;
  padding: 24px;
}

.render-pdf-points-tooltip__header {
  font-size: 20px;
  font-weight: 500;
  color: $color-white;
  line-height: 1.5;
}

.render-pdf-points-tooltip__underline {
  background-color: $color-dusty-grey;
  width: 100%;
  height: 1px;
  margin-top: 14px;
}

.render-pdf-points-tooltip__value {
  font-size: 32px;
  font-weight: 400;
  color: $color-havelock-blue;
  line-height: 1.65;

  .number-value-label__format {
    font-size: 20px;
  }
}

.render-pdf-points-tooltip__percentage {
  font-size: 20px;
  font-weight: 400;
  color: $color-dusty-grey;
  line-height: 2;
  margin-right: 8px;
  display: inline-block;
}

.render-pdf-points-tooltip__footer {
  font-size: 20px;
  font-weight: 400;
  color: $color-dusty-grey;
  line-height: 1.5;
}
