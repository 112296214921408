.number-value-label--cluster {
  height: inherit;

  .number-value-label {
    display: flex;
    justify-content: center;
    flex-direction: column;
    height: inherit;
    line-height: initial;
  }
}
