@import "../../../../styles/mixins";
@import "../../../../styles/general";

.CloseSidebarButton {
  text-align: center;
  z-index: 1;
  text-decoration: none;
  cursor: pointer;

  &.btn {
    @include breakpoint(md) {
      display: none;
    }
  }

  .btn-label {
    display: inline-block;

    @include breakpoint(md) {
      display: none;
    }
  }
}
