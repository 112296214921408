@import "../../../../styles/mixins";
@import "../../../../styles/vars";

.treemap-name {
  display: flex;
  font-size: 18px;
  margin-top: 56px;
  color: $color-white;

  @include breakpoint(xxxl) {
    font-size: 24px;
  }
}

.treemap-name__container {
  display: flex;
  align-items: center;

  @include rtl() {
    flex-direction: row-reverse;
  }
}

.treemap-name__icon {
  margin: 0 12px 0 0;
  height: 20px;

  @include breakpoint(sm) {
    height: 24px;
  }

  @include rtl() {
    margin: 0 0 0 12px;
  }
}

.treemap-wrapper {
  display: flex;
  justify-content: center;
}

.treemap-container {
  width: 100%;
  margin-top: 32px;
  height: 360px;

  @include breakpoint(sm) {
    font-size: 14px;
    height: 388px;
  }
  @include breakpoint(md) {
    height: 332px;
    max-width: 670px;
  }
  @include breakpoint(xl) {
    height: 388px;
  }
  .treemap-label-container {
    max-width: 100%;
    width: fit-content;
    height: fit-content;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    font-size: 11px;
    color: $color-mirage;
    padding: 8px;

    @include breakpoint(sm) {
      font-size: 14px;
    }

    .treemap-label {
      display: block;
      max-width: inherit;
      width: fit-content;
      height: fit-content;
      font-family: $font-family-medium;
      word-break: break-word;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    .treemap-label-text {
      width: fit-content;
      height: fit-content;
    }
  }
}
