@import "../../../../styles/mixins";
@import "../../../../styles/vars";

%icon-dimensions {
  width: 24px;
  height: 24px;
}

.Item {
  display: flex;
  justify-content: space-between;
  border-bottom: solid 2px $color-mirage;

  @include breakpoint(sm) {
    border-bottom: none;
  }

  @include breakpoint(xl) {
    justify-content: flex-start;
  }

  &--grouped {
    justify-content: flex-start;
    flex-direction: column;

    &:first-of-type {
      margin-left: 40px;
    }

    .Item__points {
      margin-bottom: 0;
    }
  }
}

.BubbleChart__items .Item {
  &:first-child {
    [class^="geo-icon-"] {
      font-size: 24px;
    }
  }
}

.Item__label {
  line-height: 2.57;
  min-width: 170px;

  @include breakpoint(sm) {
    line-height: inherit;
  }

  @include breakpoint(xl) {
    min-width: 240px;
  }

  [class^="geo-icon-"] {
    @extend %icon-dimensions;

    font-size: 12px;

    &:before {
      margin: 0;
    }
  }

  &--grouped {
    min-width: 140px;
    color: $color-white;
    font-size: 16px;
    line-height: 20px;
    margin-top: 16px;
    margin-bottom: 24px;

    .Item__icon {
      display: none;
    }
  }
}

.Item__icon {
  @extend %icon-dimensions;

  margin-left: 8px;
  display: inline-block;
}

.Item__icon--center-align {
  display: flex;
  align-items: center;
  justify-content: center;
}

.Item__points {
  display: none;
  margin-bottom: 20px;
  text-align: center;

  @include breakpoint(sm) {
    display: block;
  }

  > svg {
    overflow: visible;
  }
}

.Item__line {
  padding-left: 150px;
  padding-right: 150px;
  border-top: 1px solid $color-regent-grey;
}

.Item__income {
  display: block;
  margin-top: 10px;

  @include breakpoint(sm) {
    display: none;
  }
}

.Item__tooltip {
  pointer-events: none;
  position: absolute;
  z-index: -1;
  bottom: 32px;
  left: 0;

  .tooltip {
    background: $color-mirage !important;

    .rc-tooltip-arrow {
      bottom: -16px;

      &:after {
        background: $color-mirage;
      }
    }
  }
}

.Item__caption {
  display: flex;
  justify-content: space-between;
  font-size: 12px;
  line-height: 18px;
}

.Item__extraData {
  font-size: 10px;
  line-height: 18px;
  color: $color-regent-grey;

  &-value {
    display: inline;
  }
}

.Item__position {
  white-space: nowrap;
  margin-right: 15px;
}
