@import "../../../styles/_vars.scss";
@import "../../../styles/mixins";

.breadcrumbs__tooltip {
  opacity: 1;

  .geo-icon-asset-26white,
  .geo-icon-asset-26white__background {
    position: absolute;
    top: -26px;
    right: -36px;
    color: $color-bright-turquoise;
  }

  .geo-icon-asset-26white__background {
    background-color: $color-black;
    width: 12px;
    height: 12px;
    top: -19px;
    right: -31px;
    border-radius: 50%;
  }

  .rc-tooltip-inner {
    background-color: $color-mirage;
    border: 1px $color-bright-turquoise solid;
    border-radius: 4px;
    font-size: 16px;
    width: 285px;
    padding: 12px 25px 18px;
    text-align: right;

    .breadcrumbs__tooltip-content {
      position: relative;
    }
  }

  .rc-tooltip-arrow {
    border: 0;
    overflow: hidden;
    padding: 0;
    position: absolute;

    &:before,
    &:after {
      background-color: $color-bright-turquoise;
      border-radius: 4px;
      content: "";
      height: 100px;
      position: absolute;
      -webkit-transform: rotate(45deg);
      transform: rotate(45deg);
      width: 100px;
    }

    &:after {
      background-color: $color-mirage;
    }
  }

  &.rc-tooltip-placement-breadcrumbsBottomRight {
    .geo-icon-asset-26white {
      right: initial;
      left: -37px;

      &__background {
        right: initial;
        left: -32px;
      }
    }

    .rc-tooltip-arrow {
      width: 20px;
      height: 10px;
      top: -9px;
      right: 20%;
      margin-left: -10px;

      &:before,
      &:after {
        left: -50px;
        margin-left: 10px;
        top: 20px;
      }

      &:after {
        top: 22px;
      }
    }
  }

  &.rc-tooltip-placement-breadcrumbsRight,
  &.rc-tooltip-placement-breadcrumbsLightRight {
    .rc-tooltip-arrow {
      width: 10px;
      height: 20px;
      top: 35%;
      left: -9px;
      margin-left: 0;

      &:before,
      &:after {
        top: -50px;
        left: 20px;
        margin-top: 10px;
      }

      &:after {
        left: 22px;
      }
    }
  }

  button.geo-icon-asset-26white {
    background-color: transparent;
    border: none;
    font-size: 16px;
    padding: 5px 0 0;
  }
}
