.gm-style {
  font-family: $font-family-regular !important;
}

.gm-style > .gm-style-cc {
  display: none;
}

.gm-style > .gm-style-cc:first-of-type {
  display: inline-block;
}

.gm-style .gmnoprint,
.gm-style .gmnoprint.gm-style-cc {
  display: inline-block;
}

.gm-style .gmnoprint {
  z-index: 9 !important;
}

.gm-style .gmnoprint .gm-style-mtc div {
  padding: 5px 11px 8px !important;
  font-family: $font-family-regular !important;
}

.gm-style .gmnoprint .gm-style-mtc button {
  font-family: $font-family-regular !important;
  display: flex !important;
  align-items: center !important;
  font-size: 12px !important;
  padding: 16px 27px !important;
}

.gm-style-pbt {
  font-family: $font-family-regular !important;
}

$map-controls-btn-size: 32px;

.map-controls__single-btn-container {
  width: $map-controls-btn-size;
  height: $map-controls-btn-size;

  .map-controls__single-btn [class^="geo-icon-"] {
    display: flex;
    align-items: center;
  }
}

.map-controls {
  width: fit-content;
  height: fit-content;

  .map-controls__button {
    width: $map-controls-btn-size;
    height: $map-controls-btn-size;
  }

  .map-controls__icon {
    display: flex;
  }
}
