@import "../../styles/vars";
@import "../../styles/mixins";

.DownloadModalStatus__header {
  color: $color-white;
  font-size: 20px;
  margin: 18px 0 0;

  @include breakpoint(fullHd) {
    font-size: 24px;
  }
}

.DownloadModalStatus__message {
  color: $color-fossil-grey;
  font-size: 14px;
  margin: 20px 0 0;

  @include breakpoint(fullHd) {
    font-size: 18px;
    margin: 24px 0 0;
  }
}

.DownloadModalStatus__preloader {
  width: 50px;
  height: auto;
  margin-top: 48px;
  margin-bottom: -16px;
}

.DownloadModalStatus__icon {
  width: 48px;
  height: auto;
  align-self: center;
}

.DownloadModalStatus__button {
  background-color: transparent;
  border: 1px solid $color-bright-turquoise;
  border-radius: 5px;
  text-decoration: none;
  position: relative;
  width: 124px;
  height: 40px;
  margin-top: 64px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  @include breakpoint(fullHd) {
    width: 160px;
    height: 48px;
  }
}

.DownloadModalStatus__button__text {
  font-family: $font-family-regular;
  color: $color-bright-turquoise;

  @include breakpoint(fullHd) {
    font-size: 20px;
  }
}
