@import "../../../styles/_vars.scss";
@import "../../../styles/mixins";

.onboarding__tooltip {
  &.rc-tooltip {
    z-index: 6;
  }

  .rc-tooltip-inner {
    background-color: $color-mirage;
    border: 1px $color-bright-turquoise solid;
    border-radius: 4px;
    font-size: 16px;
    height: 150px;
    width: 263px;

    .tooltip-content {
      height: 62px;
      margin-right: 13px;
      text-align: right;
    }

    .step-counter {
      color: $color-bright-turquoise;
      height: 30px;
    }

    a {
      text-decoration: none;
    }

    .Button {
      border-color: $color-bright-turquoise;
      color: #fff;
      display: inline;
      height: 28px;
      margin-left: 10px;
      width: 98px;

      span {
        display: block;
        position: relative;
        transform: translateY(-4px);

        @include breakpoint(sm) {
          transform: translateY(-5px);
        }
      }
    }

    .secondary-button {
      color: $color-regent-grey;
      cursor: pointer;
      float: right;
      font-size: 12px;
      height: 28px;
      line-height: 22px;
      margin-right: 13px;
      text-decoration: underline;
    }
  }

  .rc-tooltip-arrow {
    border: 0;
    overflow: hidden;
    padding: 0;
    position: absolute;

    &:before,
    &:after {
      background-color: $color-bright-turquoise;
      border-radius: 4px;
      content: "";
      height: 100px;
      position: absolute;
      -webkit-transform: rotate(45deg);
      transform: rotate(45deg);
      width: 100px;
    }

    &:after {
      background-color: $color-mirage;
    }
  }

  &.rc-tooltip-placement-bottom,
  &.rc-tooltip-placement-bottomRight {
    .rc-tooltip-arrow {
      width: 20px;
      height: 10px;
      top: 0;
      margin-left: -10px;

      &:before,
      &:after {
        left: -50px;
        margin-left: 10px;
        top: 20px;
      }

      &:after {
        top: 22px;
      }
    }
  }

  &.rc-tooltip-placement-right,
  &.rc-tooltip-placement-rightTop {
    .rc-tooltip-arrow {
      width: 10px;
      height: 20px;
      top: 65px;
      left: 0;
      margin-left: 0;

      &:before,
      &:after {
        top: -50px;
        left: 20px;
        margin-top: 10px;
      }

      &:after {
        left: 22px;
      }
    }
  }

  &.rc-tooltip-placement-rightTop {
    .rc-tooltip-arrow {
      top: 32px;
    }
  }

  &.rc-tooltip-placement-top,
  &.rc-tooltip-placement-topRight {
    .rc-tooltip-arrow {
      width: 20px;
      height: 10px;
      bottom: 0;
      margin-left: -10px;

      &:before,
      &:after {
        bottom: 20px;
        left: -50px;
        margin-left: 10px;
      }

      &:after {
        bottom: 22px;
      }
    }
  }

  &.rc-tooltip-placement-topRight,
  &.rc-tooltip-placement-bottomRight {
    .rc-tooltip-inner {
      @include breakpoint(sm, true) {
        margin-right: 25px;
      }
    }
  }
}
