@import "../../../../styles/_vars.scss";
@import "../../../../styles/_mixins.scss";

.map-controls {
  height: 53px;
  width: 28px;
  border-radius: 2px;
  margin: 0 10px 22px 0;
  box-shadow: rgba($color-map-control-shadow, 0.3) 0 1px 4px -1px;

  background-color: $color-map-control-bg;
  cursor: pointer;
}

.map-controls__button {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 26px;

  &:last-child {
    border-top: 1px solid $color-map-control-separator;
  }
}

.map-controls__icon {
  font-size: 17px;
}

.map-controls__icon--disabled {
  cursor: default;
  opacity: 0.5;
}

.map-controls__single-btn-container {
  height: 28px;
  width: 28px;
  margin: 3px 10px;
  border-radius: 2px;
  box-shadow: rgba($color-map-control-shadow, 0.3) 0 1px 4px -1px;

  background-color: $color-map-control-bg;
  cursor: pointer;

  .map-controls__single-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    height: inherit;
    font-size: 17px;

    [class^="geo-icon-"] {
      height: inherit;
    }
  }
}
