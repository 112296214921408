@import "../../../styles/_vars.scss";

.TreemapTooltip {
  pointer-events: none;
  position: absolute;
  z-index: 2000;

  &.rc-tooltip {
    .tooltip {
      background: $color-mirage;
      color: $color-dodger-blue;

      .rc-tooltip-arrow {
        &:after {
          background: $color-mirage;
        }
      }
    }
  }
}
