@import "../../../../styles/mixins";

.IncomeChart {
  display: flex;
  flex-direction: column;
}

.IncomeChart__heading {
  display: none;

  @include breakpoint(sm) {
    display: flex;
    align-items: center;
    margin-bottom: 30px;
  }
}

.IncomeChart__heading--mobile {
  display: flex;
  margin-bottom: 15px;

  @include breakpoint(sm) {
    display: none;
  }
}

.IncomeChart__heading-container {
  display: flex;
  align-items: center;

  @include rtl() {
    flex-direction: row-reverse;
  }
}

.IncomeChart__heading-icon {
  height: 20px;
  margin: 0 12px 0 0;

  @include breakpoint(sm) {
    height: 24px;
  }

  @include rtl() {
    margin: 0 0 0 12px;
  }
}

// We were unable to properly encode this one icon as font. Thus, we use svg.
.geo-icon-income-all {
  background: url("../../../../assets/income-all.svg");
  background-position: 40% center;
  background-repeat: no-repeat;
  background-size: 21px;
  padding-bottom: 5px;

  &:before {
    content: "" !important;
  }
}
