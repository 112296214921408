@import "../vars";
@import "../mixins";

.infoWindow {
  position: absolute;
  z-index: 1;

  margin-top: -125px;
  margin-left: -120px;
  border: 1px solid $color-bright-turquoise;
  border-radius: 5px;
  font-family: $font-family-regular;

  background-color: $color-mirage;
  cursor: default;

  &:focus {
    outline: 0;
  }
}

.info-window__container.contextmenu-opened {
  .context-menu {
    display: block;
  }
}

.info-window__container {
  position: relative;
  z-index: 2;
  height: 100%;
  width: 240px;
  padding: 8px 0 12px;

  border-color: inherit;
  border-radius: 5px;

  background: $color-mirage;

  &--big {
    width: 228px;
  }

  .geo-icon-cancel {
    display: none;

    @include breakpoint(md) {
      position: absolute;
      top: 0;
      z-index: 1;

      display: block;
      height: 25px;
      width: 25px;
      padding-top: 0;

      color: $color-dusty-grey;

      &:hover {
        color: $color-river-bed;
      }

      text-align: center;
      font-size: 8px;
      line-height: 37px;
      cursor: pointer;

      @include ltr() {
        right: 0;
      }

      @include rtl() {
        left: 0;
      }
    }
  }
}

.info-window__icon-container {
  height: 38px;
  padding: 0 4px;
  position: absolute;
  right: 10px;
  top: -23px;

  .info-window__icon {
    height: 38px;
    max-width: 38px;
  }

  .info-window__icon__bottom-background {
    background-color: $color-mirage;
    height: 15px;
    position: absolute;
    right: 0;
    top: 22px;
    width: 100%;
    z-index: -1;
  }
}

.info-window__content {
  height: 100%;
  color: $color-white;
  font-size: 12px;
  line-height: 1.57;

  .info-window__sector-name {
    line-height: 1.5;
    margin-top: 4px;
    padding: 8px 0;

    @include rtl() {
      padding: 13px 11px 0;
    }

    @include ltr() {
      padding: 13px 11px 0;
    }
  }

  .green {
    color: $color-bright-turquoise;
  }
  .blue {
    color: $color-havelock-blue;
  }
  .purple {
    color: $color-purple;
  }
  .yellow {
    color: $color-lemon;
  }
}

.context-menu {
  position: absolute;
  z-index: 2;

  display: none;
  width: 115px;

  background: $color-river-bed;
  color: $color-white;
}

.context-menu__item {
  display: block;
  width: 100%;
  background: none;
  padding: 10px 5px;
  border: 1px solid $color-white;
  cursor: pointer;
  text-align: center;

  &:hover {
    color: $color-mirage;
    border: 1px solid $color-mirage;
    background: rgba($color-bright-turquoise, 0.5);
  }
}

.info-window__arrow-container {
  position: relative;
  bottom: -13px;

  border-color: inherit;
}

.info-window__arrow {
  position: absolute;
  z-index: 1;
  top: -9px;
  left: calc(50% - 8.315px);

  background: $color-mirage;
  height: 16px;
  width: 16px;
  transform: rotate(45deg);
  border: 1px solid $color-bright-turquoise;
  border-color: inherit;

  &.info-window__arrow--hider {
    border: 0;
    width: 24px;
    height: 12px;
    top: -13px;
    transform: rotate(0);
    left: 45%;
  }
}

.info-window__business-name {
  font-size: 12px;
  margin: 4px 11px 0;
}

.info-window__business-address,
.info-window__edit-button {
  padding: 0 11px;
}

.info-window__business-address {
  color: $color-regent-grey;
  line-height: 18px;
  margin-top: 8px;
}

.info-window__edit-button a {
  text-decoration: none;
}
