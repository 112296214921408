.scrollarea-content {
  margin: 0;
  padding: 0;
  overflow: hidden;
  position: relative;
  -ms-overflow-style: none;

  &:focus {
    outline: 0;
  }
}

.scrollarea {
  position: relative;
  overflow: hidden;

  .scrollbar-container {
    position: absolute;
    background: $color-bright-gray;
    opacity: 1;
    z-index: 10;
    border-radius: 5px;
    transition: all 0.4s;

    &.horizontal {
      width: 100%;
      height: 10px;
      bottom: 0;

      @include ltr() {
        left: 0;
      }

      @include rtl() {
        right: 0;
      }

      .scrollbar {
        width: 20px;
        height: 8px;
        background: $color-bright-turquoise;
        margin-top: 1px;
        border-radius: 5px;
      }
    }

    &.vertical {
      width: 4px;
      height: 100%;
      bottom: 0;
      top: 0;
      box-sizing: border-box;

      @include ltr() {
        right: 2px;
      }

      @include rtl() {
        left: 2px;
      }

      .scrollbar {
        width: 4px;
        height: 20px;
        background: $color-bright-turquoise;
        border-radius: 5px;
      }
    }
  }
}
