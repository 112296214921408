@import "../../styles/mixins";

.mobile-indicator {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 57px;

  a {
    transform: translateX(50%);
  }

  @include breakpoint(sm) {
    display: none;
  }

  .geo-icon-indicator {
    color: $color-bright-turquoise;
    display: block;
    font-size: 18px;
    width: 21px;
  }
}
