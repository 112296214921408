@import "../../../styles/vars";

.render-pdf-demographic-tooltip {
  border-radius: 8px;
  width: 310px;
  min-height: 200px;
  padding: 24px;
}

.render-pdf-demographic-tooltip--blue {
  border: 1px solid $color-havelock-blue;
}

.render-pdf-demographic-tooltip--pink {
  border: 1px solid $color-tickle-me-pink;
}

.render-pdf-demographic-tooltip--green {
  border: 1px solid $color-parakeet-green;
}

.render-pdf-demographic-tooltip--yellow {
  border: 1px solid $color-chenin;
}

.render-pdf-demographic-tooltip__header {
  font-size: 48px;
  font-weight: 700;
  line-height: 1.5;

  span {
    font-size: 24px;
    margin-right: 4px;
  }
}

.render-pdf-demographic-tooltip__header--blue {
  color: $color-havelock-blue;
}

.render-pdf-demographic-tooltip__header--pink {
  color: $color-tickle-me-pink;
}

.render-pdf-demographic-tooltip__header--green {
  color: $color-parakeet-green;
}

.render-pdf-demographic-tooltip__header--yellow {
  color: $color-chenin;
}

.render-pdf-demographic-tooltip__paragraph {
  font-size: 20px;
  color: $color-white;
  line-height: 1.65;
}

.render-pdf-demographic-tooltip__number {
  font-size: 20px;
  color: $color-regent-grey;
  line-height: 1.65;
}
