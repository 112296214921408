@import "../../styles/vars";
@import "../../styles/mixins";

.SaveModal__header {
  color: $color-white;
  font-size: 20px;
  margin: 18px 0 0;

  @include breakpoint(fullHd) {
    font-size: 24px;
  }
}

.SaveModal__message {
  color: $color-fossil-grey;
  font-size: 16px;
  line-height: 1.5;
  margin: 20px 0 0;

  @include breakpoint(fullHd) {
    font-size: 20px;
    margin: 24px 0 0;
  }
}

.SaveModal__input {
  background-color: transparent;
  border: 1px solid $color-fossil-grey;
  border-radius: 4px;
  color: $color-fossil-grey;
  font-family: $font-family-regular;
  font-size: 16px;
  text-align: center;
  width: 100%;
  height: 48px;
  margin-top: 40px;
  padding: 0 12px;
}

.SaveModal__row {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  width: 100%;
}

.SaveModal__button {
  width: 140px;
  margin-top: 40px;
  margin-bottom: 60px;
  cursor: pointer;
}

.SaveModal__button--active {
  background-color: $color-havelock-blue;
  border-color: $color-havelock-blue;
  pointer-events: none;
  cursor: default;
}

.SaveModal__footer {
  color: $color-fossil-grey;
  font-size: 12px;
  line-height: 1.5;
  position: absolute;
  top: 96px;
  max-width: 252px;
}
