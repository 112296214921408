.tooltip.red {
  &.rc-tooltip-placement-left {
    .rc-tooltip-arrow {
      @include ltr() {
        border-right-color: $color-mandy;
      }

      @include rtl() {
        border-left-color: $color-mandy;
      }
    }
  }

  &.rc-tooltip-placement-right {
    .rc-tooltip-arrow {
      @include ltr() {
        border-left-color: $color-mandy;
      }

      @include rtl() {
        border-right-color: $color-mandy;
      }
    }
  }

  &.rc-tooltip-placement-top {
    &,
    &Right,
    &Left {
      .rc-tooltip-arrow {
        border-top-color: $color-mandy;
      }
    }
  }

  .rc-tooltip-inner {
    background: $color-mandy;
    color: $color-white;
  }

  .rc-tooltip-content {
    border: 0;
  }
}
