.panel {
  margin-top: -11px;
}

.panel__items {
  @include sidebar-responsive-spacing(margin-top);

  @include ltr() {
    direction: ltr;
  }

  @include rtl() {
    direction: rtl;
  }
}

.panel__items--mobile {
  margin-bottom: -22px;

  @include breakpoint(sm) {
    display: none;
  }
}

.panel__items--desktop {
  display: none;

  @include breakpoint(sm) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 48px;
    row-gap: 32px;
  }
}

/**
* Panel item
*/

.panel-item {
  max-width: 240px;
  padding-top: 28px;

  @include breakpoint(sm) {
    .heading-3 {
      font-size: 40px;
    }
  }

  @include breakpoint(md) {
    .heading-3 {
      font-size: 48px;
    }
  }
}

.panel-item--with-icon {
  padding-top: 0;

  @include breakpoint(sm) {
    margin-top: 0;
  }
}

.panel-item__value {
  color: $no-sectors-chosen-value;

  .panel-item__format {
    position: relative;
    bottom: 5px;

    @include ltr() {
      right: 5px;
    }

    @include rtl() {
      left: 5px;
    }
  }
}

.panel-item__text--light {
  padding-top: 8px;
}

.panel-item__format {
  font-size: 20px;
  line-height: 20px;
}

/**
* Panel item with total number
*/
.panel-item__icon {
  color: $no-sectors-chosen-value;
  font-family: "geo-icons";
  font-size: 24px;

  &.geo-icon-money:before {
    font-weight: 600;
  }

  &.geo-icon-business {
    font-size: 28px;
  }

  &:before {
    width: 2.5em;

    @include breakpoint(sm) {
      display: flex;
    }
  }
}
