.slider {
  .slider-frame {
    height: 155px !important;

    .slider-list {
      min-height: 170px;
      height: auto !important;

      @include rtl() {
        direction: rtl !important;
        width: auto !important;
      }
      .slider-slide {
        .panel-item {
          margin: 0 auto;
        }
      }
    }
  }

  .slider-control-centerleft,
  .slider-control-centerright {
    button {
      position: relative;
      background: none !important;
      color: transparent !important;
    }
  }

  .slider-control-centerleft {
    @include rtl() {
      left: -28px !important;
    }

    &:before {
      position: absolute;
      content: "\e83d";
      font-family: "geo-icons";

      @include ltr() {
        right: 50%;
      }

      @include rtl() {
        left: 50%;
      }
    }
  }

  .slider-control-centerright {
    @include rtl() {
      right: -28px !important;
    }

    &:after {
      position: absolute;
      content: $icon-arrow-right;
      font-family: "geo-icons";

      @include ltr() {
        left: 50%;
      }

      @include rtl() {
        right: 50%;
      }
    }
  }

  .slider-control-bottomcenter {
    position: relative !important;
    display: flex !important;
    justify-content: center !important;
    margin-bottom: 20px;

    ul {
      top: 0 !important;

      @include rtl() {
        transform: scale(-1, 1);
      }

      li.paging-item {
        button {
          padding: 0 8px !important;
          fill: $color-bunker !important;
          opacity: 1 !important;

          svg {
            transform: scale(1.5);
          }
        }

        &.active {
          button {
            fill: $color-bright-turquoise !important;
          }
        }
      }
    }

    @include rtl() {
      direction: rtl;
    }
  }
}
