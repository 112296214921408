@import "../../../styles/vars";
@import "../../../styles/mixins";

.Demographic__data-info {
  text-align: center;
  font-size: 12px;
  color: $color-regent-grey;
  margin-top: 30px;
  padding-bottom: 20px;

  @include breakpoint(sm) {
    font-size: 14px;
  }

  @include breakpoint(md) {
    margin-top: 60px;
    font-size: 12px;
  }
}
