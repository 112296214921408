@import "../../styles/vars";
@import "../../styles/mixins";

.download-modal-button {
  background-color: transparent;
  border: 1px solid $color-white;
  border-radius: 5px;
  text-decoration: none;
  position: relative;
  width: 124px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  @include breakpoint(fullHd) {
    width: 160px;
    height: 48px;
  }

  &__text {
    font-size: 14px;
    color: $color-white;
    line-height: 1.71;

    @include breakpoint(fullHd) {
      font-size: 20px;
    }
  }

  &__icon {
    position: absolute;
    top: 50%;
    left: 8px;
    transform: translateY(-50%);
    width: 20px;
    height: 24px;
    filter: brightness(0) invert(1);
  }
}
