@import "../../../styles/mixins";

//
// Tooltips
// --------------------------------------------------
$font-size-base: 12px;
$line-height-base: 1.5;
$border-radius-base: 6px;
$overlay-shadow: 0 0 4px rgba(0, 0, 0, 0.17);
//** Tooltip text color
$tooltip-color: #fff;
//** Tooltip background color
$tooltip-bg: #373737;
$tooltip-opacity: 0.9;

//** Tooltip arrow width
$tooltip-arrow-width: 5px;
//** Tooltip distance with trigger
$tooltip-distance: $tooltip-arrow-width + 4;
//** Tooltip arrow color
$tooltip-arrow-color: $tooltip-bg;

// Base class
.rc-tooltip {
  z-index: 1070;
  display: block;
  visibility: visible;
  font-size: $font-size-base;
  line-height: $line-height-base;
  opacity: $tooltip-opacity;
  position: relative;

  @include breakpoint(xs) {
    position: absolute;
  }

  &-hidden {
    display: none;
  }

  &-placement-top,
  &-placement-topLeft,
  &-placement-topRight {
    padding: $tooltip-arrow-width 0 $tooltip-distance 0;
  }
  &-placement-right,
  &-placement-rightTop,
  &-placement-rightBottom {
    padding: 0 $tooltip-arrow-width 0 $tooltip-distance;
  }
  &-placement-bottom,
  &-placement-bottomLeft,
  &-placement-bottomRight {
    padding: $tooltip-distance 0 $tooltip-arrow-width 0;
  }
  &-placement-left,
  &-placement-leftTop,
  &-placement-leftBottom {
    padding: 0 $tooltip-distance 0 $tooltip-arrow-width;
  }
}

// Wrapper for the tooltip content
.rc-tooltip-inner {
  padding: 8px 10px;
  color: $tooltip-color;
  text-align: left;
  text-decoration: none;
  background-color: $tooltip-bg;
  border-radius: $border-radius-base;
  box-shadow: $overlay-shadow;
  margin: auto;
  min-height: 34px;
  width: fit-content;

  @include breakpoint(xs) {
    width: initial;
    margin: initial;
  }
}

// Arrows
.rc-tooltip-arrow {
  position: absolute;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid;
}

.rc-tooltip {
  &-placement-top &-arrow,
  &-placement-topLeft &-arrow,
  &-placement-topRight &-arrow {
    bottom: $tooltip-distance - $tooltip-arrow-width;
    margin-left: -$tooltip-arrow-width;
    border-width: $tooltip-arrow-width $tooltip-arrow-width 0;
    border-top-color: $tooltip-arrow-color;
  }

  &-placement-top &-arrow {
    left: 50%;
  }

  &-placement-topLeft &-arrow {
    left: 15%;
  }

  &-placement-topRight &-arrow {
    right: 15%;
  }

  &-placement-right &-arrow,
  &-placement-rightTop &-arrow,
  &-placement-rightBottom &-arrow {
    left: $tooltip-distance - $tooltip-arrow-width;
    margin-top: -$tooltip-arrow-width;
    border-width: $tooltip-arrow-width $tooltip-arrow-width $tooltip-arrow-width
      0;
    border-right-color: $tooltip-arrow-color;
  }

  &-placement-right &-arrow {
    top: 50%;
  }

  &-placement-rightTop &-arrow {
    top: 15%;
    margin-top: 0;
  }

  &-placement-rightBottom &-arrow {
    bottom: 15%;
  }

  &-placement-left &-arrow,
  &-placement-leftTop &-arrow,
  &-placement-leftBottom &-arrow {
    right: $tooltip-distance - $tooltip-arrow-width;
    margin-top: -$tooltip-arrow-width;
    border-width: $tooltip-arrow-width 0 $tooltip-arrow-width
      $tooltip-arrow-width;
    border-left-color: $tooltip-arrow-color;
  }

  &-placement-left &-arrow {
    top: 50%;
  }

  &-placement-leftTop &-arrow {
    top: 15%;
    margin-top: 0;
  }

  &-placement-leftBottom &-arrow {
    bottom: 15%;
  }

  &-placement-bottom &-arrow,
  &-placement-bottomLeft &-arrow,
  &-placement-bottomRight &-arrow {
    top: $tooltip-distance - $tooltip-arrow-width;
    margin-left: -$tooltip-arrow-width;
    border-width: 0 $tooltip-arrow-width $tooltip-arrow-width;
    border-bottom-color: $tooltip-arrow-color;
  }

  &-placement-bottom &-arrow {
    left: 50%;
  }

  &-placement-bottomLeft &-arrow {
    left: 15%;
  }

  &-placement-bottomRight &-arrow {
    right: 15%;
  }
}
