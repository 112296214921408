@import "../../styles/vars";
@import "../../styles/mixins";

$sidebar-item-horizontal-space: 3 * $sidebar-space;
$sidebar-spaces: 4;

.sidebar__item-layout {
  padding-left: $sidebar-item-horizontal-space;
  padding-right: $sidebar-item-horizontal-space;

  h1,
  h2,
  h3,
  h4,
  h5 {
    line-height: 1;
    margin-bottom: 0.2em; // to compensate charachters tails
  }

  h3 {
    font-size: 20px;
  }
}

@for $i from 1 through $sidebar-spaces {
  .sidebar__space-top--s#{$i} {
    padding-top: $i * $sidebar-space;
  }

  .sidebar__space-bottom--s#{$i} {
    padding-bottom: $i * $sidebar-space;
  }
}

.sidebar__item-wrapper {
  $current: &;
  position: relative;

  max-width: initial;
  transition: background-color 0.6s;

  @extend .sidebar__item-layout;
  @extend .sidebar__space-top--s2;
  @extend .sidebar__space-bottom--s2;

  &--active {
    background-color: $color-ebony-clay;
  }

  &--separated {
    &:after {
      content: "";
      position: absolute;
      left: -$sidebar-item-horizontal-space;
      bottom: 0;
      width: 100%;

      border-bottom: 1px solid rgba($color-white, 0.24);
    }

    &#{$current}--active:after {
      border-width: 2px;
      border-color: $color-white;
    }
  }

  &.sidebar__item--breadcrumbs {
    z-index: 1;
  }
}

.sidebar__item-logo {
  margin-top: auto;
}

.sidebar__container {
  padding: 0 30px;

  &.position-relative {
    position: relative;
  }

  @include breakpoint(md) {
    order: -1;
    padding: 0;
    position: relative;
  }
}

.sidebar,
.sidebar__column {
  @include breakpoint(md) {
    min-width: $sidebar-column;
    order: -1;
  }

  @include breakpoint(xl) {
    max-width: 70%;
  }

  @include breakpoint(xxl) {
    max-width: 55%;
  }

  @include breakpoint(xxxl) {
    max-width: 50%;
  }
}

.sidebar__column {
  display: flex;
  flex-flow: column;

  padding-top: $sidebar-space;
  overflow-y: auto;
  overflow-x: hidden;

  @include breakpoint(sm, true) {
    padding-top: 40px;
  }

  @include breakpoint(md) {
    background: $color-mirage;
    max-width: $sidebar-column;
  }

  @include stylingScroll($color-green-grey);
}

.sidebar__column-row {
  width: 100%;
}

.sidebar__container.open {
  .sidebar__wrapper {
    width: 100%;
  }

  .sidebar__details {
    display: block;
  }
}

.sidebar__wrapper {
  display: flex;
  flex-direction: column-reverse;
  width: 100%;
  transition: width 0.2s ease;

  @include breakpoint(md) {
    width: $sidebar-column;

    height: calc(100vh - #{$headerHeight});
    flex-direction: row;

    background: rgba($color-black, 0.4);
  }

  .sidebar__content {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    position: relative;
    height: inherit;

    @include breakpoint(sm) {
      position: static;
    }
  }
}

.sidebar__details {
  position: absolute;
  z-index: 99;
  top: 0;

  height: 100%;
  width: 100%;
  display: none;

  background: $color-ebony-clay;
  overflow-y: auto;

  @include ltr() {
    right: 0;
  }

  @include rtl() {
    left: 0;
  }

  @include breakpoint(md) {
    display: block;
    flex-basis: 0;
    flex-grow: 1;

    width: map-deep-get($container, small-desktop, width);
    overflow: visible;
    position: relative;
  }

  &::-webkit-scrollbar {
    display: none;
  }
}

.sidebar__download-btn {
  padding: 0;
}

.sidebar__badge {
  position: relative;
  width: 100%;

  @include ltr() {
    text-align: left;
  }

  @include rtl() {
    text-align: right;
  }

  @include breakpoint(md) {
    width: initial;

    @include ltr() {
      padding: 0 0 0 2 * $sidebar-badge-padding;
    }

    @include rtl() {
      padding: 0;
    }
  }

  &__wrapper {
    display: flex;
    align-items: baseline;

    @include ltr() {
      flex-direction: row-reverse;
    }
  }

  .number-value-label {
    display: flex;
    align-items: baseline;

    .number-value-label__number {
      @include ltr() {
        margin-right: 5px;
      }

      @include rtl() {
        margin-left: 5px;
      }
    }
  }
}

.sidebar__badge--breadcrumbs {
  &__text {
    font-size: 20px;

    & button {
      color: inherit;
      font-size: inherit;
      font-family: inherit;

      background: none;
      padding: 0;
      border: none;
    }
  }
}

.breadcrumbs__last-line {
  font-size: 24px;
}

.sidebar__badge--demographic,
.sidebar__badge--business {
  .sidebar__badge__icon,
  .sidebar__badge__number {
    color: $color-cyan;
  }
}

.sidebar-badge__tag {
  display: flex;
  align-items: center;
  border-radius: 5px;
  margin: 10px 0;

  color: $color-black;

  line-height: 16px;

  @include ltr() {
    text-align: left;
  }

  @include rtl() {
    text-align: right;
  }

  @include breakpoint(md, false, true, $smallDesktopHeightBreakpoint) {
    margin: 5px 0 0 0;
    line-height: 10px;
  }

  &--neutral .sidebar-badge__tag-label {
    color: $color-white;
    background: $color-trolley-grey;

    // i'm leaving it with repeated code for next
    // increament refactoring
    @include ltr() {
      padding: 5px 40px 9px;
      border-radius: 5px;
    }

    @include rtl() {
      padding: 5px 40px 9px;
      border-radius: 5px;
    }
  }
}

.sidebar-badge__tag-label {
  @include ellipsis();
  width: 100%;
  font-size: 14px;

  @include ltr() {
    padding: 7px 0 7px 7px;
    border-radius: 5px 0 0 5px;
  }

  @include rtl() {
    padding: 5px 7px 9px 0;
    border-radius: 0 5px 5px 0;
  }
}

.sidebar-badge__tag-icon {
  padding: 7px;
  font-size: 10px;
  cursor: pointer;
  border: none;
  line-height: inherit;

  @include ltr() {
    border-radius: 0 5px 5px 0;
  }

  @include rtl() {
    border-radius: 5px 0 0 5px;
  }

  @include breakpoint(md, false, true, $smallDesktopHeightBreakpoint) {
    padding: 7px;
    max-height: 24px;
  }
}

.sidebar__badge__link {
  @extend .text;
  @extend .text--dimmed;

  font-size: 18px;
  line-height: 1;
  margin-bottom: 0.2em; // to compensate charachters tails

  @include ltr() {
    text-align: left;
  }

  @include rtl() {
    text-align: right;
  }
}

.sidebar__content {
  padding: map-deep-get($container, mobile, margin-vertical)
    map-deep-get($container, mobile, margin-horizontal);

  @include breakpoint(sm) {
    padding: map-deep-get($container, desktop, margin-vertical)
      map-deep-get($container, tablet, margin-horizontal);
  }

  @include breakpoint(xxl) {
    padding: map-deep-get($container, small-desktop, margin-vertical)
      map-deep-get($container, desktop, margin-horizontal);
  }

  @include breakpoint(xxxl) {
    padding: map-deep-get($container, desktop, margin-vertical)
      map-deep-get($container, desktop, margin-horizontal);
  }
}
