@import "../../styles/vars";
@import "../../styles/mixins";

.RegionChooser {
  display: flex;
  align-items: baseline;
}

.RegionDropdownToggle {
  background: none;
  border: none;
  padding: 8px 16px;
  background: rgba($color-white, 0.06);
  width: 100%;

  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;

  &:hover {
    background: rgba($color-white, 0.09);
  }

  .RegionDropdownToggle__label {
    font-size: 20px;

    color: $color-white;
    font-family: $font-family-regular;
  }

  img {
    margin-top: -4px; // to compensate space in img
    height: 25px;
    filter: invert(100%);
    transition: all 0.5s ease;
  }
}

.RegionDropdown {
  position: absolute;
  z-index: 9999;
  margin-top: 64px;
  width: 260px;
  height: 152px;
  right: 20px;
  background-color: $color-mirage;
  border: solid 2px $color-bright-turquoise;
  border-radius: 5px;
  box-shadow: 12px 20px 20px 0 rgba(0, 0, 0, 0.22);
  text-align: right;

  &::before,
  &::after {
    content: "";
    position: absolute;
    height: 0;
    width: 0;
    border-color: transparent transparent $color-bright-turquoise transparent;
    border-style: solid;
    border-width: 0 7px 10px 7px;
  }

  &::before {
    right: 62px;
    top: -22px;
    border-color: transparent transparent $color-mirage transparent;
    border-width: 0 18px 24px 18px;
  }

  &:after {
    right: 73px;
    top: -10px;
  }

  .RegionDropdown__regions-container {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-around;
    padding: 12px 24px 8px;
  }
}

.RegionButton {
  display: flex;
  align-items: baseline;
  background-color: transparent;
  border: none;
  white-space: nowrap;

  &:hover,
  &.active {
    cursor: pointer;

    .RegionButton__label {
      text-decoration: underline;
      color: $color-viridian;
    }
  }

  .RegionButton__label {
    font-size: 18px;
    line-height: 1.67;
    color: $color-bright-turquoise;
    font-family: $font-family-regular;
  }

  img {
    height: 24px;
    width: 50px;
    margin-left: 16px;
  }
}
