@import "../../styles/vars";
@import "../../styles/mixins";

.maintenanceContainer {
  text-align: center;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: map_get($base-colors, "white");

  .zaddLogo {
    height: 40px;
    margin-bottom: 28px;

    @include breakpoint(sm) {
      height: 60px;
      margin-bottom: 32px;
    }

    @include breakpoint(fullHd) {
      height: 80px;
      margin-bottom: 48px;
    }
  }

  .maintenanceIcon {
    height: 144px;
    margin-bottom: 28px;

    @include breakpoint(sm) {
      height: 164px;
      margin-bottom: 32px;
    }

    @include breakpoint(fullHd) {
      height: 288px;
      margin-bottom: 48px;
    }
  }

  .header {
    font-weight: bold;

    font-size: 20px;
    margin-bottom: 12px;
    max-width: 315px;

    @include breakpoint(sm) {
      font-size: 28px;
      margin-bottom: 12px;
      max-width: 532px;
    }

    @include breakpoint(fullHd) {
      max-width: 660px;
      font-size: 36px;
      margin-bottom: 16px;
    }
  }

  .description {
    font-size: 16px;
    margin-bottom: 20px;
    max-width: 315px;

    @include breakpoint(sm) {
      font-size: 20px;
      max-width: 532px;
      margin-bottom: 28px;
    }

    @include breakpoint(fullHd) {
      font-size: 24px;
      max-width: 660px;
      margin-bottom: 32px;
    }
  }

  .callToAction {
    color: map_get($base-colors, "turqoise");

    font-size: 16px;
    margin-bottom: 28px;

    @include breakpoint(sm) {
      font-size: 20px;
      margin-bottom: 32px;
    }

    @include breakpoint(fullHd) {
      font-size: 24px;
      margin-bottom: 48px;
    }
  }

  .ninetenthsLogo {
    width: 80px;

    @include breakpoint(sm) {
      width: 100px;
    }
  }
}
