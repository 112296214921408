@import "../../../../styles/mixins";
@import "../../../../styles/vars";

.IncomeTable {
  align-self: center;
  width: 100%;
  padding: 0 0.35rem;

  @include breakpoint(md) {
    max-width: 680px;
    padding: 0 0.5rem;
  }
}
