.btn-label {
  width: 260px;

  font-family: $font-family-regular;
  font-size: 16px;

  @include breakpoint(md, true) {
    @include rtl() {
      margin-top: -6px;
    }
  }
}

.business-section__scroll-container {
  & + .sidebar__close-btn {
    margin-top: 25px;

    @include breakpoint(md) {
      margin-top: 0;
    }
  }
}
