@import "../../styles/vars";
@import "../../styles/mixins";

.flash-modal__container {
  position: absolute;
  z-index: 1;
  top: 55px;
  right: 30px;
  left: 30px;
}

.flash-modal {
  position: relative;

  max-width: 100%;

  margin: auto;
  padding: 10px 20px;
  border: 2px solid $color-white;
  border-radius: 10px;
  outline: none;

  background: rgba($color-white, 0.23);
  color: $color-white;

  font-size: 18px;
  font-weight: 500;
  text-align: center;

  @include breakpoint(md) {
    width: 480px;
  }

  .RoundButton {
    background: $color-white;
  }
}

.flash-modal--data-wrapper {
  padding: 20px;

  @include breakpoint(md) {
    width: 285px;
  }
}

.flash-modal--success {
  border: 2px solid $color-success;
  background: rgba($color-success, 0.7);

  .RoundButton {
    background: $color-success;
  }
}

.flash-modal--error {
  border: 2px solid $color-pomegranate;
  background: rgba($color-scarlet, 0.7);

  .RoundButton {
    background: $color-pomegranate;
  }
}

.flash-modal__round-button {
  position: absolute;
  top: -20px;
  left: -20px;
}
