@import "../../../../styles/_vars.scss";
@import "../../../../styles/_mixins.scss";

.autocomplete {
  position: relative;
  z-index: 0;
  top: 10px;
  right: 10px;
  color: $color-input-text;
  font-family: $font-family-light;

  .geo-icon-search,
  .geo-icon-cancel {
    z-index: 1;

    display: inline-block;
    width: 40px;
    height: 40px;
    border-radius: 5px;

    line-height: 40px;
    text-align: center;
  }

  .geo-icon-search {
    background: $color-btn-primary-bg;

    @include ltr() {
      float: left;
    }

    @include rtl() {
      float: right;
    }

    &:before {
      font-size: 16px;
    }
  }

  .geo-icon-cancel {
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;

    &:before {
      font-size: 9px;
    }
  }
}

.autocomplete.open {
  .geo-icon-cancel {
    width: 30px;
    display: none;
    opacity: 1;

    &:hover {
      cursor: pointer;
    }
  }

  .geo-icon-search {
    @include ltr() {
      border-radius: 0 5px 5px 0;
    }

    @include rtl() {
      border-radius: 5px 0 0 5px;
    }
  }

  .autocomplete__input {
    width: 250px;
    border: 0;
    opacity: 1;
    font-family: $font-family-regular;

    cursor: text;

    @include ltr() {
      border-radius: 5px 0 0 5px;
    }

    @include rtl() {
      border-radius: 0 5px 5px 0;
    }
  }

  .autocomplete__list {
    display: block;
  }
}

.autocomplete.open.typed {
  .geo-icon-cancel {
    display: flex;
  }
}

.autocomplete.loaded {
  .autocomplete__input {
    @include ltr() {
      border-radius: 5px 0 0 0;
    }

    @include rtl() {
      border-radius: 0 5px 0 0;
    }
  }
}

.autocomplete__input {
  height: 40px;
  width: 0;

  display: inline-block;
  padding: 6px 12px;
  border: 0;
  border-radius: 5px;

  background: $color-input-bg;
  background-image: none;
  box-shadow: 0 1px 3px 0 rgba($color-black, 0.25);

  cursor: pointer;
  opacity: 0;
  transition: width 175ms cubic-bezier(0.4, 0, 0.2, 1),
    opacity 175ms cubic-bezier(0.4, 0, 0.2, 1), border-radius 0.01s linear;

  @include ltr() {
    float: left;
  }

  @include rtl() {
    float: right;
  }
}

// Note: it should be done in that way,
// because in simplier version doesn't work
.autocomplete__input {
  &::-webkit-input-placeholder {
    line-height: 25px;
    color: $color-input-placeholder;
  }

  &::-moz-placeholder {
    line-height: 25px;
    color: $color-input-placeholder;
  }

  &::placeholder {
    line-height: 25px;
    color: $color-input-placeholder;
  }

  &--safari {
    &::-webkit-input-placeholder {
      line-height: 15px;
      color: $color-input-placeholder;
    }
  }
}

.autocomplete__input-container {
  overflow: hidden;
  max-width: 290px;
}

#pac-card {
  .autocomplete.dark {
    border: solid 2px transparent;
    border-radius: 28px;

    .autocomplete__input-container {
      padding: 0 5px;
      display: flex;
      align-items: center;
      flex-direction: row-reverse;
      max-width: 100%;
      cursor: default;

      .geo-icon-search {
        display: flex;
        align-items: center;
        justify-content: center;
        order: 1;
        width: 54px;
        height: 54px;
        border-radius: 50%;

        transition: color, background 175ms ease-out;

        &:before {
          font-size: 14px;
        }

        &:hover {
          cursor: pointer;
        }
      }

      .autocomplete__input {
        padding: 5px 0;
        order: 1;
        width: 200px;
        height: 54px;
        background-color: transparent;
        color: $color-white;

        &:hover {
          cursor: default;
        }

        &::placeholder {
          color: $color-white;
        }
      }
    }

    .autocomplete__children-elements {
      position: static;
      width: 40px;
      display: flex;
      justify-content: center;
      color: $color-regent-grey;
    }
  }

  .autocomplete.dark.open {
    border: solid 2px $color-bright-turquoise;
    border-radius: 28px;
    background-color: $color-mirage;

    transition: all 0.5s ease-out;

    .geo-icon-search {
      color: $color-bright-turquoise;
      background: none;
      border: none;
    }

    .autocomplete__input {
      &:hover {
        cursor: text;
      }
    }
  }

  .autocomplete.dark.loaded {
    border-radius: 10px;
    border-width: 4px;
    top: 8px;
    right: 8px;

    transition: all 0.5s ease-out;
  }
}

// Overwritten styles for google map autocomplete
.autocomplete__list-item {
  border-top: 0;
  border-bottom: 1px solid $color-list-item-border;
  padding: 0 12px;

  font-family: $font-family-regular;
  font-size: 11px;
  line-height: 35px;
  text-overflow: ellipsis;
  white-space: nowrap;

  cursor: default;
  overflow: hidden;

  &:hover {
    background: $color-list-item-bg-hover;
  }

  &.active {
    background: $color-list-item-bg;
  }

  .autocomplete__list-item-main {
    color: $color-mirage;
    font-size: 13px;
  }

  .autocomplete__list-item-secondary {
    color: $color-ebony-clay;
    font-size: 11px;
  }
}

.autocomplete__list-item-main-text {
  @include ellipsis();

  display: flex;
  justify-content: space-between;
}

#pac-card.top {
  z-index: 9999999 !important;
}

.pac-logo::after {
  display: none;
}

.autocomplete__list {
  width: 250px;
  display: none;
  border-top: 1px solid $color-list-container-border;

  background-color: $color-list-container-bg;
  box-shadow: 0 2px 6px rgba($color-black, 0.3);
  color: $colot-list-container-text;

  text-align: right;
  font-size: 11px;
  line-height: 35px;

  overflow: hidden;
  cursor: default;

  @include ltr() {
    border-radius: 5px 5px 0 0;
  }

  @include rtl() {
    border-radius: 0 0 5px 5px;
  }
}

.autocomplete__list.dark {
  width: auto;
  background-color: $color-mirage;
  color: $color-white;
  padding: 0 20px;
  border-top: 1px solid rgba($color-bright-turquoise, 0.3);
  box-shadow: none;

  max-height: 380px;
  overflow: auto;

  @include breakpoint(xs) {
    max-height: unset;
  }

  .autocomplete__list-item {
    padding: 6px 0;
    border-bottom: 0;

    &.active {
      background: $color-mirage;
    }

    &:hover {
      background: $color-mirage;
      cursor: pointer;
      opacity: 0.5;
    }

    .autocomplete__list-item-main {
      color: $color-white;
      font-size: 14px;
    }

    .autocomplete__list-item-secondary {
      color: $color-bright-turquoise;
      font-size: 12px;
    }
  }
}

.autocomplete__children-elements {
  position: absolute;
  top: 0;
  display: flex;
  flex-direction: row-reverse;

  line-height: 40px;

  @include rtl() {
    left: 40px;
  }

  @include ltr() {
    right: 40px;
  }
}

.map__container {
  .autocomplete__input {
    padding: 0;
  }

  .autocomplete.open {
    .autocomplete__input {
      padding: 4px 12px 6px 30px;
    }
  }
}
