@import "../../../styles/vars";
@import "../../../styles/mixins";

$headerHeight: 40px;
$wideHeader: 1900px;

.Header {
  height: $headerHeight;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 110;
}

.Header__container {
  align-items: center;
  background-color: $color-black;
  display: flex;
  height: 100%;
  justify-content: space-between;
  margin: 0 auto;
  padding: 0 20px;
  transition: all 0.5s ease;

  @include breakpoint(sm) {
    padding: 0 22px;
  }

  @include breakpoint(md) {
    padding-left: 29px;
  }

  @include breakpoint(xxl) {
    padding: 0 37px;
  }

  @include breakpoint(xxxl) {
    padding: 0 52px;
  }

  @include breakpoint($wideHeader) {
    padding: 0 87px;
  }

  .logo {
    height: 24px;
  }
}

.Header__logo {
  min-width: 140px;
  height: 24px;
  display: flex;
  justify-content: space-between;
  color: $color-white;
  font-size: 14px;
  line-height: 1.4;
}

.Header__logo-image {
  margin-left: 10px;

  @include breakpoint(sm) {
    margin-right: 10px;
  }

  @include breakpoint(xxl) {
    margin-right: 2px;
  }

  @include breakpoint(xxxl) {
    margin-right: 3px;
  }
}

%middle-align-in-logo {
  display: inline-block;
  height: 24px;
  vertical-align: middle;
}

.Header__logo-item {
  @extend %middle-align-in-logo;

  min-width: 72px;

  .clear-link {
    @extend %middle-align-in-logo;
  }
}
