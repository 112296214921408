@import "../../styles/vars";
@import "../../styles/mixins";

.ButtonIcon {
  font-family: $font-family-regular;
  font-size: 14px;
  cursor: pointer;
}

.btnIcon {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 88px;
  width: 132px;
  padding: 0;
  border: 2px solid $color-bright-turquoise;
  background-color: transparent;

  border-radius: 16px;
  color: $color-bright-turquoise;

  @include breakpoint(sm) {
    height: 88px;
    width: 160px;
    font-size: 16px;
  }

  @include breakpoint(md) {
    height: 112px;
    width: 200px;
    font-size: 18px;
  }

  @include breakpoint(xxl) {
    height: 88px;
    width: 160px;
    font-size: 16px;
  }

  @include breakpoint(fullHd) {
    height: 112px;
    width: 200px;
    font-size: 18px;
  }

  &:hover {
    color: $color-white;
    border-color: $color-white;

    .btnIcon__icon {
      filter: brightness(0) invert(1);
    }
  }

  .btnIcon__icon {
    height: 36px;
    margin-bottom: 4px;

    @include breakpoint(md) {
      height: 48px;
    }

    @include breakpoint(xxl) {
      height: 36px;
    }

    @include breakpoint(fullHd) {
      height: 48px;
    }
  }
}
