@import "../../../styles/vars";

.rc-tooltip.base-tooltip.chart {
  position: absolute;
  width: 240px;

  .rc-tooltip-arrow {
    &:after {
      background: $color-mirage;
    }
  }

  .rc-tooltip-inner {
    width: initial;
    margin: initial;

    .tooltip {
      background: $color-mirage;
    }
  }
}

.chart-tooltip-header {
  border-bottom: 1px solid $color-emperor;
}

.chart-tooltip-header__title {
  display: flex;
  padding: 0 10px 10px 10px;
  color: $color-white;
  font-family: $font-family-regular;
  font-weight: 700;
}

.chart-tooltip-values,
.chart-tooltip-value__label {
  padding: 0 10px;
}

.chart-tooltip-values {
  position: relative;
  display: flex;
  align-items: baseline;
}

.chart-tooltip-value--percentage,
.chart-tooltip-value__label {
  color: $color-dusty-grey;
}

.chart-tooltip-value--percentage {
  padding-right: 5px;
}

.chart-tooltip-value__container {
  margin-left: 5px;

  .number-value-label {
    display: inline-flex;
    align-items: baseline;

    .number-value-label__number {
      font-size: 30px;

      .number-value-label__format {
        font-size: 12px;
      }

      .valueSuffix__formatted {
        margin-left: 5px;
      }
    }
  }
}

.chart-tooltip__border-pointer {
  position: absolute;
  top: calc(50% - 2px);
  right: -7px;
}

.chart-tooltip__border {
  position: absolute;
  right: 6px;
  top: -2px;

  width: 1px;
  height: 13px;

  background: $color-mirage;
}

.chart-tooltip__pointer {
  position: absolute;

  width: 12px;
  height: 8px;
  border-width: 4px;
  border-style: solid;
  border-radius: 4px;
}

.chart-tooltip-value-suffix {
  display: flex;
  align-items: baseline;

  .chart-tooltip-suffix {
    font-size: 12px;
    margin: 0 4px;
  }
}

.chart-tooltip-children {
  padding: 0 10px;
  display: flex;
}
