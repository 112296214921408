@import "_vars.scss";

// Breakpoints
@mixin breakpoint(
  $breakpoint,
  $is-max-width: false,
  $is-max-height: false,
  $height-value: 0
) {
  @if map-has-key($breakpoints, $breakpoint) {
    @if ($is-max-width) {
      @media (max-width: #{map-get($breakpoints, $breakpoint)}) {
        @content;
      }
    } @else if ($is-max-height) {
      @media (min-width: #{map-get($breakpoints, $breakpoint)}) and (max-height: $height-value) {
        @content;
      }
    } @else if ($is-max-width == false) {
      @media (min-width: #{map-get($breakpoints, $breakpoint)}) {
        @content;
      }
    }
  } @else {
    @if ($is-max-width) {
      @media (max-width: $breakpoint) {
        @content;
      }
    } @else {
      @media (min-width: $breakpoint) {
        @content;
      }
    }
  }
}

@function map-deep-get($map, $keys...) {
  @each $key in $keys {
    $map: map-get($map, $key);
  }

  @return $map;
}

//TODO Autoprefixer for webpack
@mixin translate($x, $y) {
  -webkit-transform: translate($x, $y);
  -ms-transform: translate($x, $y); // IE9 only
  -o-transform: translate($x, $y);
  transform: translate($x, $y);
}

@mixin sidebar-responsive-spacing($property) {
  #{$property}: map-deep-get($container, mobile, spacing);

  @include breakpoint(sm) {
    #{$property}: map-deep-get($container, tablet, spacing);
  }

  @include breakpoint(xxl) {
    #{$property}: map-deep-get($container, small-desktop, spacing);
  }

  @include breakpoint(xxxl) {
    #{$property}: map-deep-get($container, desktop, spacing);
  }
}

@mixin ellipsis() {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

// Directions
@mixin rtl() {
  [dir="rtl"] & {
    @content;
  }
}

@mixin ltr() {
  [dir="ltr"] & {
    @content;
  }
}

@mixin typography($typographies) {
  @each $name, $declarations in $typographies {
    .#{$name} {
      @each $property, $value in $declarations {
        @if ($property == breakpoints) {
          @each $breakpoint, $breadpointDeclarations in $value {
            @include breakpoint($breakpoint) {
              @each $breakpointProperty,
                $breakpointValue in $breadpointDeclarations
              {
                #{$breakpointProperty}: $breakpointValue;
              }
            }
          }
        } @else {
          #{$property}: $value;
        }
      }
    }

    @each $color, $value in $base-colors {
      .#{$name}--#{$color} {
        @extend .#{$name};

        color: $value;
      }
    }
  }
}

@mixin stylingScroll($thumb-color, $track-color: $color-bright-gray) {
  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-track {
    border-radius: 5px;
    background: $track-color;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background: $thumb-color;
  }

  // for firefox
  & {
    scrollbar-width: thin;
    scrollbar-color: $thumb-color $track-color;
  }
}
