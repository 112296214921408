@import "../../../styles/mixins";
@import "../../../styles/vars";
@import "../../../styles/typography";

.panel-item__value--with-label {
  .number-value-label__format {
    @extend .heading-5;

    @include rtl() {
      margin-right: 3px;
    }
  }
}

.panel-item__value--with-label {
  @include breakpoint(md) {
    font-size: 40px;
    margin-top: 10px;
  }

  @include breakpoint(xl) {
    font-size: 48px;
    margin-top: 0;
  }
}

.paragraph-2--dark-gray {
  .panel-item__value--with-label {
    margin-top: 0;
    font-size: 14px;
  }
}

.panel-item__text--subtitle {
  .number-value-label__format {
    @extend .paragraph-2;
  }
}
