@import "../../styles/vars";
@import "../../styles/mixins";

@mixin activeButton($colorValue) {
  @media (hover: hover) {
    &:hover {
      background-color: rgba($colorValue, 0.5);
      border-color: $colorValue;
    }
  }

  @media (hover: none) {
    &:active {
      background-color: rgba($colorValue, 0.5);
      border-color: $colorValue;
    }
  }
}

.Button {
  font-family: $font-family-regular;
  font-size: 16px;
  text-transform: uppercase;
  cursor: pointer;
}

.btn {
  position: relative;

  display: flex;
  align-items: center;
  justify-content: center;
  height: 41px;

  border: 1px solid $color-white;
  border-radius: 4px;

  font-family: $font-family-regular;
  font-size: 16px;
  color: $color-white;
  background-color: $color-mirage;

  transition: all 0.2s ease;

  @media (hover: hover) {
    &:hover {
      color: $color-black;
      border-color: $color-black;
    }
  }

  &:disabled,
  &[disabled] {
    color: $color-emperor;
    background-color: $color-ebony-clay;
    border-color: $color-outer-space;
  }
}

.btn--block {
  width: 100%;
}

.btn--shadow {
  &:before {
    position: absolute;
    top: -26px;
    content: "";
    display: block;

    height: 25px;
    width: 100%;

    background: linear-gradient(rgba($color-ebony-clay, 0), $color-ebony-clay);
  }
}

@each $colorName, $colorValue in $btn-colors {
  .btn--outline-#{$colorName} {
    border-color: $colorValue;
    color: $colorValue;

    @include activeButton($colorValue);
  }
}

@each $colorName, $colorValue in $btn-colors {
  .btn--full-#{$colorName} {
    border-color: $colorValue;
    background-color: $colorValue;
    color: $color-black;

    @include activeButton($colorValue);
  }
}
