@import "../../styles/vars";
@import "../../styles/mixins";

.fancy-scroll {
  height: inherit;

  overflow-y: auto;
  overflow-x: hidden;

  -webkit-overflow-scrolling: auto;
}

.fancy-scroll.fancy-scroll--mobile {
  -webkit-overflow-scrolling: touch;
}

.fancy-scroll--desktop::-webkit-scrollbar {
  width: 6px;
}

.fancy-scroll--mobile::-webkit-scrollbar-thumb {
  background: $color-bright-gray;
}

.fancy-scroll--desktop::-webkit-scrollbar-track {
  border-radius: 5px;
  background: $color-bright-gray;
}

.fancy-scroll--desktop::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background: $color-bright-turquoise;
}

.fancy-scroll--desktop--demographic::-webkit-scrollbar-thumb,
.fancy-scroll--desktop--business::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background: $color-cyan;
}

.fancy-scroll.fancy-scroll--mobile {
  padding-right: 0;

  @include ltr() {
    margin-right: -25px;
    padding-right: 25px;
  }

  @include rtl() {
    margin-left: -25px;
    padding-left: 25px;
  }

  &.sectors-details__scroll-container {
    margin-right: -22px;

    @include breakpoint(xxxl) {
      margin-right: -28px;
    }
  }

  &.business-section__scroll-container,
  &.demographic-section__scroll-container {
    @include rtl() {
      padding-right: 30px;
    }

    @include ltr() {
      padding-left: 30px;
    }

    @include breakpoint(xxl) {
      @include rtl() {
        padding-right: 0;
      }

      @include ltr() {
        padding-left: 0;
      }
    }
  }
}
