@import "../../styles/vars";

.RoundButton {
  width: 40px;
  height: 40px;
  margin: 0;
  border-radius: 50%;

  background: $color-white;
  box-shadow: 4px 0 4px 0 rgba($color-black, 0.36);

  cursor: pointer;

  .geo-icon-cancel {
    color: $color-mirage;

    font-size: 15px;
    line-height: 15px;
  }
}
