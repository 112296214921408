@import "../../../../styles/vars";
@import "../../../../styles/mixins";
@import "../../../../styles/typography";

.SidebarValue {
  line-height: 65px;

  @include ltr() {
    margin-right: -4px;
  }

  @include rtl() {
    margin-left: -4px;
  }
}

.SidebarValue {
  .number-value-label__format {
    font-family: $font-family-light;
    font-size: 18px;
    line-height: 28px;
  }

  .number-value-label__number {
    font-size: 40px;
    line-height: 1;
  }
}
