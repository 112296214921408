@import "../vars";
@import "../mixins";
@import "../typography";

/*
 * Chart
 */

.businessChart {
  @include breakpoint(md) {
    width: 775px;
  }
}

.chart {
  @include sidebar-responsive-spacing(margin-top);
}

.chart {
  h4[class^="heading"] {
    margin-bottom: 15px;

    @include breakpoint(sm) {
      margin-bottom: 20px;
    }

    @include breakpoint(xxxl) {
      margin-bottom: 30px;
    }
  }
}

.chart__container {
  margin-top: 15px;

  @include breakpoint(sm) {
    margin-top: 20px;
  }

  @include breakpoint(xxxl) {
    margin-top: 30px;
  }
}

.chart__wrapper--pyramid {
  display: flex;
  justify-content: center;
}

.chart--simple-chart {
  padding-left: 10px;

  @include breakpoint(xs) {
    max-width: 750px;
  }

  @include breakpoint(sm) {
    max-width: 680px;
  }

  @include breakpoint(md) {
    max-width: 610px;
  }

  @include breakpoint(xl) {
    max-width: 800px;
  }
}

.chart__container--pyramid {
  margin: 0;

  @include breakpoint(md) {
    max-width: 680px;
  }

  .chart__content {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
}

.chart__content {
  position: relative;
}

.demographic-section__content {
  .demographic-section__transform * {
    transform: translate3d(0, 0, 0);
  }

  .panel {
    .number-value-label {
      @include breakpoint(sm) {
        display: flex;
        align-items: flex-end;
      }
    }
  }
}

.demographic-section__scroll-container,
.business-section__scroll-container {
  height: 100%;
  pointer-events: auto;

  @include rtl() {
    padding-left: 26px;
  }

  @include ltr() {
    padding-right: 26px;
  }

  @include breakpoint(xxl) {
    @include rtl() {
      padding-left: 55px;
    }

    @include ltr() {
      padding-right: 55px;
    }
  }
}

/*
 * Bars
 */

.bar {
  height: 10px;
  background: $color-oxford-blue;
  margin-top: 5px;
  border-radius: 15px;
}

.bar__visible-part {
  height: inherit;
  width: 0;
  transition: width 0.8s ease-in-out 0.5s;
  background: $color-havelock-blue;
  border-radius: 15px;

  &.animation-disabled {
    transition-property: none;
  }
}

.chart__bar-items {
  position: relative;
  z-index: 3;
}

.chart__bar-items--business {
  padding: 15px 0 20px 0;

  @include breakpoint(sm) {
    padding: 15px 0 30px 0;
  }
}

.bar__label--sector-name {
  padding-top: 15px;
}

/*
 * Axis
 */

.axis-y {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
}

.axis-y__line {
  position: absolute;
  height: 100%;
  border-right: 2px solid $color-black;

  &:first-child {
    display: none;
  }
}

.axis-x {
  bottom: -1px;
  z-index: 3;
  height: 30px;
  width: 102%;
  border-top: 1px solid rgba($color-regent-grey, 0.5);
  background: $color-ebony-clay;
}

.axis-x--shadow {
  &:before {
    position: absolute;
    top: -51px;
    content: "";
    height: 50px;
    width: 100%;
    background: linear-gradient(rgba($color-ebony-clay, 0), $color-ebony-clay);
    opacity: 1;
    transition: opacity 0.3s ease-in;
    pointer-events: none;
  }
}

.axis-x--hidden-shadow {
  &:before {
    opacity: 0;
  }
}

.axis-x__point {
  position: absolute;
  white-space: nowrap;
}

.scrollarea .axis-x {
  @include breakpoint(md) {
    margin-left: 70px;
  }
}

.sticky {
  position: sticky;
  position: -webkit-sticky;
}

/**
 * Pyramid
 */

.pyramid-labels {
  flex-basis: 72px;
  max-width: 72px;
  width: 72px;
}

.pyramid-label-sum {
  color: $color-cyan;

  .panel-item__format {
    font-size: inherit;
    line-height: inherit;
  }

  .demographic-value {
    font-size: 10px;

    &:before {
      content: "(";
    }

    &:after {
      content: ")";
    }
  }
}

.pyramid-label-item {
  margin-bottom: 5px;
}

.pyramid-label-item__label {
  color: $color-regent-grey;
  word-spacing: -3px;
}

.pyramid-label-value {
  @extend .paragraph-4;

  text-align: center;
}

.simple-bar {
  position: relative;
  z-index: 2;
  height: 26px;
  margin-top: 5px;
}

.simple-bar__line {
  height: 12px;
  border-radius: 100px;
  max-width: 0;
  padding: 0;
  background: $color-havelock-blue;
}

.simple-bar__line--animated {
  transition: max-width 0.8s ease-in-out 0.5s;
}

.simple-bar__line-container {
  height: inherit;
  color: $color-white;
  font-size: 10px;
  transform: translateX(-2px);

  &.reverse {
    transform: translateX(2px);
  }
}

.simple-axis-x {
  height: 26px;
  border-top: 1px solid $color-ghost;
}

.simple-axis-x__item {
  position: absolute;

  .panel-item__format {
    font-size: inherit;
  }
}

.simple-bar-chart__bars {
  position: relative;
  padding-bottom: 8px;
}

.simple-axis-y {
  position: absolute;
  top: 0;
  z-index: 1;
  height: 100%;

  @include rtl() {
    right: 0;
  }

  @include ltr() {
    left: 0;
  }

  .reverse & {
    @include rtl() {
      left: 0;
    }

    @include ltr() {
      right: 0;
    }
  }
}

.simple-axis-y__item {
  position: absolute;
  height: 100%;
  width: 2px;
  background: $color-black;
}

.simple-legend__color {
  width: 15px;
  height: 5px;
  border-radius: 100px;
}

.simple-legend__label {
  text-transform: lowercase;
}

.pyramid-legend {
  padding-top: 15px;

  .simple-legend {
    .simple-legend__color {
      @include rtl() {
        margin: 4px 0 0 5px;
      }

      @include ltr() {
        margin: 1px 0 0 5px;
      }
    }

    &.reverse {
      .simple-legend__color {
        @include rtl() {
          margin-right: 5px;
        }

        @include ltr() {
          margin-left: 5px;
        }
      }
    }
  }
}

.pyramid-legend__divider {
  position: relative;

  .pyramid-legend__fill {
    position: absolute;
    top: -37px;
    left: 0;
    bottom: 0;
    right: 0;
    height: 100%;
    width: 79%;
    margin: 0;
    background: $color-ebony-clay;

    @include breakpoint(sm) {
      width: 85%;
    }
  }
}

.pyramid-divider {
  bottom: 66px;
  z-index: 20;
  height: 1px;
  margin: 129px -8px 0 -8px;
  background-color: $sticky-menu-bg;
  border-bottom: 1px solid $color-ghost;
}
