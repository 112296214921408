* {
  -webkit-tap-highlight-color: transparent;
  outline: none;
}

.safari {
  .sectors-details__scrolled-list {
    @include ltr() {
      margin-right: 0;
    }

    @include rtl() {
      margin-left: 0;
    }
  }
}
