@import "../../../styles/vars";

.render-pdf-component {
  background-color: $color-dark-grey;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 80px 160px;
  height: 1414px;
  width: 2000px;
  z-index: 99999;
}

.render-pdf-component__header {
  display: flex;
  justify-content: space-between;
}

.render-pdf-component__header__title {
  font-size: 48px;
  font-weight: 400;
  color: $color-white;
  line-height: 1.5;
}

.render-pdf-component__header__region {
  border-bottom: 4px solid $color-havelock-blue;
  font-size: 70px;
  font-weight: 500;
  color: $color-white;
  line-height: 1.5;
  padding-bottom: 10px;
  width: 1058px;

  span {
    display: block;
    font-size: 48px;
    font-weight: 400;
    line-height: 1.5;
    padding-bottom: 4px;
  }
}

.render-pdf-component__header__image {
  width: auto;
  margin-top: 16px;
  margin-left: 8px;
  height: 70px;
}

.render-pdf-component__content {
  position: relative;
  display: flex;
  flex-direction: column;

  .fancy-scroll {
    min-height: 315px;
    padding-bottom: 15px;
    overflow: visible;
  }

  .business-section__scroll-container .panel-item:first-child {
    margin-top: 28px;
  }

  .panel {
    height: max-content;
    padding-bottom: 10px;

    .number-value-label,
    .percentage-value {
      font-size: 70px;
    }

    .number-value-label__format,
    .percentage-value__format {
      font-size: 42px;
    }

    .heading-4 {
      display: none;
    }
  }

  .panel__items--desktop {
    display: flex !important;
    gap: unset !important;
  }

  .panel-item {
    flex-basis: 226px;
    max-width: 226px;
    width: 226px;
    position: relative;
    margin-left: 60px !important;
  }

  .panel-item__icon {
    font-size: 38px;

    &.geo-icon-business {
      font-size: 42px;
      position: absolute;
      top: -48px;
    }
  }

  .panel-item__text {
    font-size: 22px;

    .number-value-label {
      font-size: 22px;

      span {
        font-size: 22px;
      }
    }
  }

  .panel-item:first-child {
    flex-basis: 248px;
    max-width: 248px;
    width: 248px;
  }

  .demographic-section__scroll-container,
  .business-section__scroll-container {
    margin-right: 0;
  }

  .business-section__scroll-container {
    .chart__content {
      width: 1174px;
    }
  }

  .chart__container {
    width: 1015px;
    margin-right: 0;
    pointer-events: none;

    .bar-item {
      margin-top: 4px;

      .bar {
        height: 12px;
      }
    }

    .bar__label {
      font-size: 24px;
      line-height: 1.75;
    }

    .axis-x {
      font-size: 20px;
      background-color: transparent;
    }
  }

  .gender-chart,
  .nationality-chart {
    .translated-title {
      font-size: 24px;
      margin-bottom: 32px;
    }

    .translated-title__icon {
      height: 32px;
    }

    .chart__wrapper--pyramid {
      justify-content: flex-start;
    }

    .chart__content {
      width: 1200px;
    }

    .simple-bar {
      margin-bottom: 26px;
    }

    .simple-bar__line {
      height: 16px;
    }

    .pyramid-labels {
      flex-basis: 120px;
      max-width: 120px;
      width: 120px;
    }

    .pyramid-label-item__label,
    .pyramid-label-value {
      font-size: 20px;
    }

    .simple-axis-x {
      transform: translateZ(0);
    }

    .simple-axis-x__item {
      margin: 0 24px;
    }

    .axis-x--shadow {
      background-color: transparent;

      .number-value-label {
        font-size: 20px;
      }
    }

    .simple-legend__label {
      font-size: 20px;
    }
  }

  .IncomeChart__heading {
    font-size: 24px;
  }

  .IncomeChart__heading-icon {
    height: 32px;
  }

  .IncomeTable {
    align-self: flex-start;
    max-width: 752px;
  }

  .IncomeTableEastern {
    max-width: 752px;
  }

  .IncomeTableGroup {
    margin-top: 80px;
  }

  .IncomeTableGroup__title {
    font-size: 24px;
    margin-bottom: 20px;
  }

  .IncomeTableGroup__icon {
    height: 32px;
  }

  .IncomeTableItem {
    border-bottom: 2px solid $color-night-rider;
    align-items: flex-start;
  }

  .IncomeTableItem__label,
  .IncomeTableItem__income {
    font-size: 24px;
    margin-bottom: 8px;
  }

  .IncomeTableItem__icon {
    margin-left: 20px;

    // As was written in IncomeChart.scss - we were unable to properly encode this one icon as font. Thus, we use svg.
    .geo-icon-income-all {
      background-size: 32px;

      &::before {
        width: 32px;
      }
    }

    i {
      font-size: 20px;
    }
  }

  .BubbleChart__group {
    justify-content: space-between;

    .Item {
      flex: 1;

      text {
        font-size: 20px;
      }
    }

    .Item:first-child {
      margin-left: 100px;
    }

    .Item__caption {
      margin-top: 40px;
    }

    .Item__label,
    .Item__position {
      font-size: 20px;
    }
  }

  .BubbleChart--fill {
    width: 1200px;
    margin-right: 0;
    font-size: 20px;
  }

  .treemap-wrapper {
    display: block;
  }

  .treemap-container {
    min-width: 1200px;
    min-height: 650px;
  }

  .treemap-name {
    margin-top: 0;
  }

  .treemap-name__container {
    font-size: 24px;
  }

  .treemap-name__icon {
    height: 32px;
  }

  .treemap-label {
    font-size: 20px;
  }

  .treemap-label-text {
    font-size: 20px;
  }
}

.render-pdf-component__content__info {
  font-size: 18px;
  color: $color-regent-grey;
  margin-top: 120px;
}

.render-pdf-component__footer {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}

.render-pdf-component__footer__page {
  font-size: 24px;
  color: $color-white;
  margin-bottom: 8px;
}

.render-pdf-component__footer__image {
  width: auto;
  height: 96px;
}
