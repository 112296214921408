@import "../../../../styles/vars";
@import "../../../../styles/mixins";

.IncomeTableGroup {
  margin-bottom: 40px;

  @include rtl() {
    flex-direction: row-reverse;
  }

  &__title {
    display: flex;
    align-items: center;
    color: $color-white;
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 16px;

    @include breakpoint(xxxl) {
      font-size: 24px;
    }
  }

  &__icon {
    margin-left: 0;
    margin-right: 12px;
    height: 20px;

    @include breakpoint(sm) {
      height: 24px;
    }

    @include rtl() {
      margin-left: 12px;
      margin-right: 0;
    }
  }
}
