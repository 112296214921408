@import "../../../styles/vars";
@import "../../../styles/mixins";

:root {
  --tooltip-width: 100px;
  --tooltip-height: 50px;
}

.onboarding__bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba($color-black, 0.7);
  z-index: 4;

  @include breakpoint(md) {
    height: 100vh;
  }

  & + .map div .gmnoprint {
    z-index: initial !important;
  }
}

.onboarding__target {
  z-index: 5 !important;
  pointer-events: none;
  background-color: $color-mirage;

  &--scroll {
    pointer-events: initial;
    background-color: transparent;

    &::before {
      content: "";
      position: absolute;
      transform: translate(-15%, -100%);
      width: 31px;
      height: 31px;
      border-radius: 50%;
      background-color: $color-bright-turquoise;
    }
  }
}

.onboarding__target-parent {
  z-index: 4 !important;
}

.onboarding-shadow-target--scroll {
  transform: translate(-15%, -150%);
}
