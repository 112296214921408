@import "../../styles/vars";
@import "../../styles/mixins";

.SimpleModal {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  background-color: $color-mirage;
  min-height: 284px;
  width: 324px;
  padding: 28px 24px;
  box-shadow: 0 5px 22px 0 $color-obsidian;
  border: solid 1px $color-ebony-clay;
  border-radius: 5px;
  position: relative;

  @include breakpoint(sm) {
    width: 504px;
    padding: 28px 80px;
  }

  @include breakpoint(md) {
    width: 608px;
    padding: 40px 88px;
  }

  @include breakpoint(fullHd) {
    width: 810px;
    padding: 80px 132px;
  }
}

.SimpleModal__overlay {
  position: fixed;
  inset: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba($color-mirage, 0.8);
  z-index: 100;
}

.SimpleModal__body--open {
  overflow: hidden;
}

.SimpleModal__close {
  background-color: transparent;
  border: none;
  position: absolute;
  top: 16px;
  right: 16px;
  cursor: pointer;
}
